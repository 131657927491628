<template>
	<div class="cockpitview2">


		<div v-if="1==2" style="position:absolute; right:10px;z-index: 99999; width: 350px; border: 1px solid red; padding: 10px; font-size: 10px;">
			{{ debug }}
		</div>

		<b-modal v-model="confirmplan" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
			<template v-slot:modal-header>
				<h3 class="modal-title">Confirm delete track</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="confirmplan = false"></i>
				</a>
			</template>
			<span class = "text-danger"> Warning:</span> You are about to delete this track. Do you want to proceed?
			<template v-slot:modal-footer="{ cancel, ok }">
				<button-gruppe text1= "Delete track" @no ="cancel" @yes = "confirmdeletetrack(activetrack)"></button-gruppe>
			</template>
		</b-modal>

		<b-modal @shown="milestonemodalshown" @hide="canceleditmilestone" v-model="milestonemodal" id ="milestonemodal" size="sm-p" no-close-on-esc no-close-on-backdrop >
			<template v-slot:modal-header>
				<h3 class="modal-title">{{ addmilestoneeditmode ?  'Add a' : 'Update' }}  milestone</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="milestonemodal = false"></i>
				</a>
			</template>

			<template>

				<div class="clearfix pb-2 pl-2 pr-2 editmilestoneform">

					<div class="row mb-3">
						<label for="inputEmail3" class="col-sm-3 col-form-label">Name:</label>
						<div class="col-sm-9">
							<input :readonly="!canwrite" type="text" class="form-control rounded-left" v-model="editmilestones" maxlength="50" ref="editmilestonesinput" style="border-color: var(--N200);" placeholder="Give the milestone a name.">
						</div>
					</div>

					<div class="row mb-3">
						<label for="inputdesc" class="col-sm-3 col-form-label">Description:</label>
						<div class="col-sm-9">
							<textarea v-if="canwrite" v-model="editmilestonesdescription" ref="editmilestonesdescription" class="form-control rounded-left" style="min-height: 65px; border-color: var(--N200);" placeholder="Describe the milestone and the criteria for marking it as complete."></textarea>
							<div v-else class="p-2 border" v-html="nl2br(editmilestonesdescription)" style="min-height: 65px; border-color: var(--N200);"></div>
						</div>
					</div>


					<div class="row mb-3">
						<label for="inputEmail3" class="col-sm-3 col-form-label">Date</label>
						<div class="col-sm-9">
							<datepicker style="width: 170px;" size="sm" :canwrite="canwrite"  @statechanged="milestonedatestatechanged" @ondatepicked="dodatepickedmilestone" :datevalue="trackdates.milestones.value" :defvalue="trackdates.milestones.value"></datepicker>
						</div>
					</div>


					<div class="row mb-3">
						<label for="inputEmail3" class="col-sm-3 col-form-label">Status</label>
						<div class="col-sm-9">
							<div v-if="!canwrite" class="input-group-text plain p-0 w-sm-100">
								<i :class="statusselected.icon + ' mt-1 mr-2' " :style="'color: var(--'+statusselected.color+')'"></i> {{ statusselected.label }}
							</div>
							<div v-else class="input-group-text plain p-0 w-sm-100">
								<b-dropdown id="milestone-status-1" class="p-0">
									<template #button-content>
										<i :class="statusselected.icon" :style="'color: var(--'+statusselected.color+')'"></i> {{ statusselected.label }}
									</template>
									<b-dropdown-item :key="key" v-for="(option,key) in statusoptions" @click="statushasbeenselected(option)"> <i :class="option.icon" :style="'color: var(--'+option.color+')'"></i> {{ option.label }}</b-dropdown-item>
								</b-dropdown>

							</div> 
						</div>
					</div>


					<div class="row mb-3">
						<label for="inputEmail3" class="col-sm-3 col-form-label">On critical path?</label>
						<div class="col-sm-9">
							<div v-if="canwrite" class="input-group-text plain w-sm-100">
								<b-form-checkbox v-model="iscriticalpath" inline name="check-button" id="iscriticalpath" size="lg" switch >
								</b-form-checkbox> 
							</div>
							<div v-else class="input-group-text plain">
								{{ iscriticalpath ? 'Yes' : 'No' }}
							</div>
						</div>
					</div>						

				</div>
			</template>


			<template v-slot:modal-footer="{ cancel, ok }">


				<template v-if="!canwrite">

					<button v-if="addmilestoneeditmode==false" type="button" class="btn btn-primary mr-1" @click="milestonemodal = false">Close</button> 

				</template>
				<template v-else >

					<button v-if="addmilestoneeditmode==false" type="button" class="btn btn-danger mr-1" @click.stop.prevent="trydeletemilestone(editmilestonesid)">Delete milestone</button> 

					<button type="button" class="btn btn-secondary mr-1" @click.stop.prevent="canceleditmilestone">Cancel</button> 

					<button type="button" class="btn btn-primary mr-1" @click.stop.prevent="presavemilestone" :disabled="milestonedatestate==2">Save milestone</button> 

				</template>

			</template>
		</b-modal>

		<b-modal v-model="sorttracks" @shown="enablesorttracks" @hidden="trackssorted" id ="approvalchanges2" size="sm" no-close-on-backdrop no-close-on-esc >
			<template v-slot:modal-header>
				<h3 class="modal-title">Reorder tracks</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="sorttracks = false"></i>
				</a>
			</template>

			<div class="mb-3" >
				Reorder, hide or show tracks on the master plan gantt chart.
			</div>

			<ul id="sortabletracks" class="list-group p-2">

				<li @contextmenu.prevent.stop="" class="list-group-item p-2 sortedtracks border-top-0 border-left-0 border-right-0" :id="track._id" :key="track._id" v-for="track in alltracks" :class="{'notsortable':tracks.length<=1}">
					<span class="d-flex">
					<span class="pr-2"><i class="fas fa-grip-lines draghandle"></i></span>
					<span class="pr-2 draghandle">{{ track.label }}</span>
					<span class="flex-grow-1 text-right">
						<a @click.stop.prevent="track.hidden=true" href="#" v-if="!track.hidden" title="Click to hide"><i class="fa-light fa-eye" ></i></a>
						<a @click.stop.prevent="track.hidden=false" href="#" v-else title="Click to show"><i class="fa-light fa-eye-slash" ></i></a>
					</span>
					</span>
				</li>

			</ul>


			<div class="mb-3 mt-3" v-if="1==2">
				Drag track here to hide it from project.
				<ul id="hiddentracks" class="list-group border p-2 mt-2">
					<li @contextmenu.prevent.stop="" class="list-group-item p-2 sortedtracks border-top-0 border-left-0 border-right-0" :id="track._id" :key="track._id" v-for="track in filterhidden(alltracks,true)">
						<i class="fas fa-grip-lines"></i> <span class="ml-2 disable-select">{{ track.label }}</span> 
					</li>
				</ul>
			</div>

			<template v-slot:modal-footer="{ cancel, ok }">
				<button-gruppe text1= "Save" @no ="cancel" @yes="updatetracksort()"></button-gruppe>
			</template>
		</b-modal>

		<b-modal @hide="addnewtrackformhidden" @shown="addnewtrackformshown" v-model="addnewtrack" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
			<template v-slot:modal-header>
				<h3 class="modal-title">Add track</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="addnewtrack = false"></i>
				</a>
			</template>
			<form onsubmit="return false" v-if="canwrite" class="mt-2">       	 		


				<div class="mb-3">
					<label class="form-label">Track name</label>
					<input class="form-control card p-2 noshadow readonly titletext mb-0" type="text" v-model="formtracklabel" ref="newtracknameinput" placeholder="Give the project track a name."> 
					<span class="text-danger hide" v-if="!allowsave">name is required</span>
				</div>

				<div class="mb-3">
					<label class="form-label">Description</label>
					<textarea class="form-control card p-2 noshadow readonly titletext mb-0 autogrow"  style="overflow: hidden; overflow-y: auto; height: auto; min-height: 72px;" v-model="formtrackdescription" ref="formtrackdescriptioninput" placeholder="Add some details about the track."></textarea>
				</div>

				<button @click="createnewtrack()" style="position: absolute; left: -100000px; height: 1px;" type="submit"></button>




			</form>

			<template v-slot:modal-footer="{ cancel, ok }">

				<div v-if="withtrackupdates>0" class="text-success flex-fill">{{withtrackupdates}} track{{withtrackupdates>1?'s':''}} added</div>


				<button class="ml-1 btn btn-secondary" @click="addnewtrack = false" type="button">{{ withtrackupdates > 0 ? 'Close' :   'Cancel'}}</button>


				<button v-show="allowsave" class="ml-1 btn btn-secondary" @click="createnewtrack(false)" type="button">Save and close</button>

				<button v-show="allowsave" class="ml-1 btn btn-primary" @click="createnewtrack(true)" type="button">Save and add another</button>

				<button v-show="!allowsave" class="ml-1 btn btn-secondary" type="button" disabled="disabled">Save and close</button>
				<button v-show="!allowsave" class="ml-1 btn btn-primary" type="button" disabled="disabled">Save and add another</button>

			</template>
		</b-modal>



		<div class="">
			<div class="" id="masterplanwrapper" :class="{ 'pb-2':windowspec=='lg'}">


				<div :id="alltracks.length == 0 ? 'interactivepowersliderempty' : 'interactivepowerslider' " style="position:relative; z-index: 10;">

					<div class="mt-2 ml-2 mb-2">
						<button ref="addnewtrackbutton" v-if="canwrite" class="btn btn-primary" @click="addnewtrack=true"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add track</button>
						<b-dropdown id="prepexport" text="Export" class="m-md-2 ml-1">
							<b-dropdown-item :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)" :disabled="template.disabled">
								<i v-if="template.type=='ppt'" class="ppt fas fa-file-powerpoint mr-2"></i> 
								<i v-if="template.type=='doc'" class="doc fas fa-file-word mr-2"></i> 
								<i v-if="template.type=='xls'" class="xls fas fa-file-excel mr-2"></i>{{ template.label }}
							</b-dropdown-item>
						</b-dropdown>

					</div>

					<div v-if="alltracks.length == 0" id="masterplanwrapper">The Master plan is created when the first track is added. <br> Click on "Add track"</div>

					<div v-if="graphworking" style="z-index: 70; background-color: #ffffff; height: 100%; width: 90%; position: absolute; top: 0; left:0;"><img :src="savedpng"></div>

					<div :class="windowspec" id="masterplan" v-show="alltracks.length > 0" @contextmenu="contextmenu($event)" @mousemove="graphover" @click="graphclicked">
					</div>

					<div class="legend d-flex flex-wrap" v-if="alltracks.length > 0">
						Milestones 
						<div class="legend-item ml-2"><div class="dot2 dotblack"></div> Done</div>       	 	

						<div class="legend-item ml-2"><div class="dot2 dotred"></div> Delayed</div>


						<div class="legend-item ml-2"><div class="dot2 dotgreen"></div> On-track</div>


						<div class="legend-item ml-2"><div class="dot3 dotcritical"></div> Critical path</div>
					</div>

				</div>





			</div>
			<div  class=""  id="masterplansidebar" :class="{ 'lg':windowspec=='lg', 'border-left':windowspec=='lg', 'sticky-top':windowspec=='lg', 'pl-2 pr-2 pb-2':windowspec=='lg', 'm-auto':windowspec!='lg', 'mobile':ismobile }">


				<div >		


					<template >			

						<div v-if="tracks.length>0 && activetrack._id">

							<div :class="{ 'p-0':ismobile }">

								<div class="mt-2">

									<div class="d-flex align-items-center">
										<div class="flex-grow-1" >

											<div v-if="canwrite"  >   

												<div v-if="!editlabel">
													<h3 v-if="!activetrack.label" class="m-0 fw-bold">--</h3>
													<h3 v-else-if="activetrack.label==''" class="m-0 fw-bold">--</h3>
													<h3 v-else class="m-0 fw-bold"><span class="editable2" @click="doeditlabel">{{ activetrack.label }}</span></h3>
													<span class="text-danger" v-if="activetrack.label==''">name is required</span>
												</div>

												<div class="has-success mt-2" v-else>

													<input type="" name="trackname" class="form-control card p-2 noshadow readonly titletext mb-0" v-model="activetracklabel" ref="inputtracklabel" maxlength="60">

													<div class="text-right mt-1">
														<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1 btn-sm" type="button">Cancel</button>
														<button @click.stop.prevent="updatetrack( 'label', activetracklabel)" class="btn btn-primary btn-sm" type="button">Save</button>
													</div>

												</div>



											</div>
											<div v-else class="">
												<h3 >{{ activetrack.label }} </h3>
											</div>
										</div>
										<div>

											<b-dropdown v-if="canwrite && !editlabel" id="deletecloserisk" text="..." class="m-md-2" right no-caret variant="link" toggle-class="text-decoration-none">
												<template #button-content>
													<i aria-hidden="true" class="far mr-0 fa-ellipsis-v" style="font-size: 15px;"></i>
												</template>
												<b-dropdown-item  @click="trydeletetrack()"><span class="actiondelete">Delete track</span></b-dropdown-item>
											</b-dropdown>	
										</div>
									</div>         	

									<div class="mb-3">

										<label class="form-label h4label">Description</label> 

										<div class="has-success" :class="{'editable2':canwrite}" >
											<div @click.stop.prevent="doeditdescription" class="card noshadow" :class="{'pb-4':showmoredesc}" v-if="!editdescription" style="position: relative;">


												<div id="inputtrackdescriptionviewer" ref="inputtrackdescriptionviewer" v-show="editdescription == false" class="p-2 readonly titletext mb-0 description" style="min-height: 52px;" v-html="nl2br(activetrackdescription)" :class="{'showless':!showmoredesc}">         		
												</div>


												<div class="showlessbox" :class="{'showlessbtnwrapper':isoverflowed(activetrackdescription)}" >

													<div :class="{'grad':!
													showmoredesc}">&nbsp;</div>
													<div style="background: #fff;" class="p-1">
														<div @click.stop.prevent="showmoredesc=showmoredesc?false:true" v-if="isoverflowed(activetrackdescription) && !showmoredesc && editdescription == false" class="text-center showmorebtn p-1"><i style="font-size: 16px;" class="fal fa-angle-down"></i> Show more</div>
														<div @click.stop.prevent="showmoredesc=showmoredesc?false:true " v-if="isoverflowed(activetrackdescription) && showmoredesc && editdescription == false" class="text-center showlessbtn p-1"><i style="font-size: 16px;" class="fal fa-angle-up"></i> Show less</div>
													</div>
												</div>

											</div>
										</div>

										<textarea id="inputtrackdescriptioneditor" onfocus='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' oninput='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' style="min-height: 123px;" v-show="editdescription==true" name="trackdescription" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activetrackdescription" ref="inputtrackdescription" placeholder="Add some details about the track." ></textarea>

										<div class="text-right mt-1"  v-if="editdescription==true">
											<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
											<button @click.stop.prevent="onsavedescription( activetrackdescription )" class="btn btn-primary" type="button">Save</button>
										</div>


									</div>

									<div class="mb-3">          	  	

										<div>
											<template v-if="!canwrite">       	


												<b-input-group class="" style="width: 100%;">

													<template #prepend>
														<b-input-group-text class="plain" >Start date</b-input-group-text>
													</template>

													<div class="input-group-text plain">
														{{ trackdates.editstart.value }} 
													</div>  

												</b-input-group>

												<b-input-group class="" style="width: 100%;" >
													<template #prepend>
														<b-input-group-text class="plain" >End date</b-input-group-text>
													</template>
													<div class="input-group-text plain">
														{{ trackdates.editend.value }}
													</div>
												</b-input-group>


											</template>

											<template v-if="canwrite">

												<datepicker class="mb-3" :canwrite="canwrite && editdescription==false" @ondatepicked="dodatepickedstart" :datevalue="trackdates.editstart.value" :defvalue="trackdates.editstart.value" :prependstr="'Start date'" ></datepicker>


												<datepicker :canwrite="canwrite && editdescription==false" @ondatepicked="dodatepickedend" :datevalue="trackdates.editend.value" :defvalue="trackdates.editend.value" :prependstr="'End date'" :mindate="editmindate"></datepicker>

											</template>	
										</div>


									</div>


									<div class="mb-3">


										<label class="h4label">Track lead</label>  

										<ownerPicker :isdisabled="editdescription==true" :singleuser="true" :activeriskobject="activetrack" :team="team" @onupdaterisk="updatetrackowner" :canwrite="canwrite" :boardusers="boardusers" :limittoboard="cockpit._id"></ownerPicker>

									</div>


									<div class="pt-2">

										<div class="">


											<div class="h4label">
												Milestones
											</div> 

											<a v-if="addmilestoneeditmode == false && canwrite == true && editdescription==false" @click="addmilestone()" class="btn btn-primary float-right">Add milestone</a>

											<a  v-if="addmilestoneeditmode == false && canwrite == true && editdescription==true" class="disabled btn btn-primary float-right">Add milestone</a>


										</div>


										<!-- style="max-height: 250px; overflow-y: scroll;" -->

										<div class="mb-1">





											<template>


												<div v-if="activetrack.milestones && activetrack.milestones.length > 0" class="">
													<table class="table table-borderless mb-0" >
														<tr>
															<td>&nbsp;</td>
															<td class="p-2" nowrap="nowrap">Date</td>
															<td class="p-2" style="word-break: break-word;">Name</td>

														</tr>
														<tr class="selectable" :class="{'even':key%2==0, 'canwrite':canwrite}" :key="key" v-for="( milestone, key) in activetrack.milestones" @click.prevent="updatemilestone(milestone)">
															<td class="p-1" style="width:20px; position: relative;" align="center" valign="center"><div class="dot2" :class="{ 'dotcritical':milestone.iscritical, 'dotgreen':milestone.status=='green', 'dotred':milestone.status=='red', 'dotblack':milestone.status=='black' }"></div>  </td>
															<td nowrap="nowrap" class="p-1" style="width:30%;" >{{ milestone.eventdate }}		</td>
															<td class="p-1" style="word-break: break-word;"> {{ milestone.label }} </td>

														</tr>
													</table>
												</div>
											</template>



										</div>         		

									</div>

								</div>




								<div class="mb-1 mt-3" v-if="canwrite && 1==2">

									<button class="btn btn-danger mr-2" @click="trydeletetrack()">Delete track</button>

								</div>

							</div>
						</div>

					</template>



				</div>


			</div>
		</div>




		<div v-if="1==2" id="floating" class="card" style="position:absolute; background: #ffffff; left: 50px;">
			xx{{ displayww }}xx
		</div>





	</div>
</template>

<style type="text/css">

	.legend-wrapper{

		width: calc(100%)

	}
	.legend{
		border: solid 1px var(--N300);
		min-width: fit-content;
		max-width: 360px;
		margin: auto;
		padding: 5px;
	}

	.dot2 {
		height: 10px;
		width: 10px;
		display: inline-block;
		border-radius: 5px;
	}

	.dot3 {
		height: 10px;
		width: 10px;
		border-radius: 5px;
		margin: auto;
		background-color: var(--N600)
	}

	.dot4{
		border: solid 3px var(--N800);
		height: 10px;
		width: 10px;
		display: inline-block;
		border-radius: 5px;
	}

	.dotcritical{
		border-radius: 0px !important;
	}

	.criticalline{
		width: 20px;
		height: 10px;
		background-image: url(/static/critical.png);
		background-position: center;
		background-repeat: repeat-x;
		display: inline-block;
	}

	.dotgreen{  background-color: var(--G500) }
	.dotred{ background-color: var(--R500) }
	.dotyellow{ background-color: var(--Y500) }
	.dotblack{ background-color: var(--N800) }

	.modal-open {
		overflow: scroll !important;
	}

	.editable2:hover{
		cursor: text;
	}

	.showlessbtn:hover{
		opacity: 1;
		transition: opacity .2s;
	}

	.showlessbox{
		/*opacity: 0;*/
	}

	.grad{
		/*background: linear-gradient(to bottom, rgba(255,255,255,0) 70%,rgba(255,255,255,1) 100%);*/
		
		background: url(/static/grad.png) repeat 0 0;
		height: 50px;
		/*background: red;*/
	}

	.h4label{
		font-size: 16px;
		/*font-weight: bold;*/
	}

	.showlessbtnwrapper{
		position: absolute;bottom: 0; 
		width: 100%
	}

	.showmorebtn{
		font-size: 12px;
		width: 100%;
		background: #fff;
		cursor: pointer;
	}

	.showlessbtn{
		font-size: 12px;
		width: 100%;
		background: #fff;
		/*opacity: 0;*/
		cursor: pointer;
	}

	.showlessbtn:hover{
		background: var(--N100);
	}
	.showmorebtn:hover{
		background: var(--N100);
	}

	.seemorelink{
		position: absolute;
		width: 300px;
		top: 80%;
		left: 50%;
		margin-left: -150px;
		background: #fff;
	}

	#masterplansidebar{
		background: #fff;
		min-width: 360px;
		max-width: 360px;		
	}

	#masterplansidebar.mobile{
		min-width: auto;
		max-width: auto;
	}

	#masterplansidebar.lg{
		position: fixed;
		height: 90vh;
		top: 72px;		
		right: 0px;
		overflow-y: scroll;
	}


	@media(max-width:768){
		div{
			background-color: red !important;
		}
	}

	.showless{
		max-height: 133px;
		overflow-y: hidden;
	}

	.ppt{
		color: #cc4628;
		font-size: 18px;
	}

	.doc{
		color: #245595;
		font-size: 18px;
	}

	.xls{
		color: #209e63;
		font-size: 18px;
	}

	.vs--open .vs__open-indicator {
		transform: none !important;
	}

	.rounded-left{
		border-top-left-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}

	.masterplanstatuschooser .vs__search{
		/* visibility: hidden;*/
		height: 1px;
		opacity: 0;
	}

	.masterplanstatuschooser .vs__dropdown-toggle{
		width: 260px;
	}


	.checkbox-form{
		height: 38px;
	}


	@media screen and (max-width: 400px) {
		.editable .description{
			padding-right: 35px !important;
		}

		.w-sm-100{
			width: 100% !important;
		}
	}


	@media(max-width: $breakpoints-md){




		.checkbox-form{
			height: 48px !important;
		}
	}

	.selectable{
		cursor: pointer !important;
	}
	.selectable:hover{
		opacity: .9;
	}

	#masterplandetails .vs__actions {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		padding: 0 13px !important;
	}


	.vs__selected{
		/*display: none !important;*/
	  /*margin: 0 !important;
	  padding: 0 !important;*/
	  margin: 0 0 0 0 !important;
	  padding: 0 0 !important;
	}

	.vs__dropdown-option:hover{
		color: #000 !important;
	}

	.vs__open-indicator{
		color:#000 !important;
		margin: auto
	}

	.vs__dropdown-option--highlight{
		background-color: $N100;
		color:#000 !important;
	}


	.btn-notabutton{
		font-size: 15px;
	}

	/*.vs__dropdown-toggle, .vs__search{

    background:#fff !important;
    }*/

    .disable-select {
    	user-select: none; /* supported by Chrome and Opera */
    	-webkit-user-select: none; /* Safari */
    	-khtml-user-select: none; /* Konqueror HTML */
    	-moz-user-select: none; /* Firefox */
    	-ms-user-select: none; /* Internet Explorer/Edge */
    }


    #masterplan.sm div.apexcharts-pan-icon, #masterplan.sm div.apexcharts-zoomin-icon, #masterplan.sm div.apexcharts-zoomout-icon, #masterplan.sm div.apexcharts-zoom-icon, #masterplan.sm div.apexcharts-reset-icon, #masterplan.sm div.sort-tracks-icon, #masterplan.sm div.apexcharts-toolbar-custom-icon {
    	transform: scale(1.0) !important;
    	position: relative;
    	left: 1px;
    	top: 0px;
    	width: 48px;
    	padding-right: 0px;
    	padding-left: 20px;
    	font-size: 20px;
    	margin-left: -10px;
    }

    #masterplan div.apexcharts-pan-icon, #masterplan div.apexcharts-zoomin-icon, #masterplan div.apexcharts-zoomout-icon, #masterplan div.apexcharts-zoom-icon, #masterplan div.apexcharts-reset-icon, #masterplan div.sort-tracks-icon, #masterplan div.apexcharts-toolbar-custom-icon {
    	transform: scale(1.0) !important;
    	position: relative;
    	left: 1px;
    	top: 0px;
    	width: 48px;
    	padding-right: 0px;
    	padding-left: 8px;
    	font-size: 15px;
    }

    .apexcharts-element-hidden{
    	display: none !important;
    }

    #apexchartsmasterplanchart{
    	z-index: 50 !important;
    }


    #masterplansidebar, #inputtrackdescriptioneditor {
    	scrollbar-width: thin;          /* "auto" or "thin" */
    	scrollbar-color: var(--N200);
    }

    html::-webkit-scrollbar-track
    {
    	background-color: #FFFFFF !important;
    }

    html::-webkit-scrollbar
    {
    	width: 10px;
    	background-color: #F5F5F5 !important;
    }

    html::-webkit-scrollbar-thumb
    {
    	background-color: #c9c8c8 !important;
    }
    html::-webkit-scrollbar-thumb:hover
    {
    	background-color: #b3b2b2 !important;
    }


</style>


<style type="text/css" scoped>



	.custom-control-inline{
		margin-right: 0;
	}

	.input-group-append{
		background-color: #ffffff !important;
	}

	.even{
		background-color: #f5f6f8;
	}

	.input-group-text{
		padding-left: 0;
	}

	.milestone-item.canwrite{
		cursor: pointer;
	}


	.plain{
		background: none !important;
		border: none;
		width: 100px;
	}


	::v-deep .apexcharts-bar-area:hover {
		fill: #B5C3E3 !important;
	}

	::v-deep .apexcharts-rangebar-area {
		margin-top: -50px;
	}

	::v-deep .apexcharts-menu-item.exportCSV {
		display: none;
	}

	.lwidth_lg{
		float: left;
		padding-right: 2rem !important;
	}
	.rwidth_lg{
		float: left;
		width: 390px;
	}

	.lwidth_md{
		width: 400px;
		/*margin: auto;*/
	}
	.rwidth_md{
		/*margin: auto;*/
		width: 390px;
	}

	.lwidth_sm{
		/*margin: auto;*/
	}
	.rwidth_sm{
		margin: auto;
		/*width: 336px;*/
	}

	.narrow_lg{
		margin-top: 15px;
		margin-left: 10px;
	}
	.narrow_md{
		margin-top: 15px;
		margin-left: 10px;
	}
	.narrow_sm{
		margin-top: 15px;
	}

	.minwidth_sm{
		max-width: 360px;
	}

	.minwidth_md{
		min-width: 510px;
	}

	.minwidth_lg{
		min-width: 580px;
	}

	.action{
		font-size: 140%;
		text-align: center;
	}

	
	.green{ color: var(--G500) }
	.red{ color: var(--R500) }
	.yellow{ color: var(--Y500) }
	.blue{ color: var(--B200) }

	.white{ color: #ffffff; }

	.contact-selected{
		border: solid 2px blue;
	}

	.plan{
		border: solid 1px #000;
		padding: 3px;
		background-color: #fff;
	}


	.vdr.active::before{
		outline: none;
	}

	.ui-selectable:hover{
		cursor: pointer;
	}


	.highlight{
		border: solid 1px #000;
		padding: 5p ;
	}

	.table th, .table td{
		padding: 0;
	}

	


	/*.ui-draggable-dragging{
		transform: scale(1.7);
		}*/

		.ui-selected .marker{
			transform: scale(1.2);
		}

		.touch-start{
			transform: scale(1.5);
		}

	/*.ui-selected span{
		position: relative;
		width: auto;
		}*/
	</style>
	<script type="text/javascript"></script>

	<script>

	//test

	import Vue from 'vue'

	import Sortable from 'sortablejs';

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

	import Emailsapi from '@/services/api/emails'
	import {bus} from '@/main'

	import memberlistfull from '@/components/members/memberlistfull'
	

	import vueselect from '@/views/tools/vueselect'
	import moment from 'moment';
	import ApexCharts from 'apexcharts'


	import ownerPicker from './ownerPicker'

	import AWS from 'aws-sdk'

	const ID = process.env.VUE_APP_CLIENT;
	const SECRET = process.env.VUE_APP_CRET;

	// The name of the bucket that you have created
	const BUCKET_NAME = 'floweimage';
	AWS.config.update({region: 'eu-west-3'});

	const s3Bucket = new AWS.S3({
		accessKeyId: ID,
		secretAccessKey: SECRET,
		apiVersion: '2006-03-01', 
		region: 'eu-west-3',
		params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
	});



	export default{
		name: 'boardmasterplan',
		data(){
			return{
				//showlessdesckey:0,
				//descriptionboxoverflown:false,		
				page:'details',
				debug:{},
				doexportready:false,
				presavemilestonetimer:null,
				cancelevent:false,
				withtrackupdates:0,
				milestonedatestate:0,
				milestonemodal:false,
				attributes: { 'ref': 'openIndicator', 'role': 'presentation', 'class': 'vs__open-indicator'	},
				statusselected : {},
				statusoptions : [ { color:"green", icon:"fas fa-circle", label:"On-track" }, { color:"black", icon:"fas fa-circle", label:"Done" }, { color:"red", icon:"fas fa-circle", label:"Delayed" } ],
				doupdatecriticalpath: true,
				minDate : new Date(),
				tracklistsorted : [],
				sorttracks : false,
				addmilestoneeditmode: false,
				uploadedgraphimage:"",
				savedpng:null,
				graphworking:false,
				milestonelabelbox : [],
				milestonelabeltext : [],
				milestonemarkers : [],
				gridcolor1: '#111',
				gridcolor2: '#111',
				trackcolor : '#B5C3E3', //b200
				tracktextcolor : '#313338', //n900				
				cpcolor : '#da6060', //R300
				cplinecolor: '#deb76a', //Y300
				notcpcolor : '#696f7b', //N700
				lastzoom:null,
				zoom2:null,
				criticalpathrendered:false,
				lastaddedtrack:null,
				lastselectedtrack:null,
				iscriticalpath:false,
				trackisenabled:false,
				editmilestones:"",
				editmilestonesdescription:"",
				editmilestonesid:null,
				milestonesstatus:"idle",
				formtracklabel:"",
				formtrackdescription:"",
				trackname:"",
				trackdescription:"",
				activetrackdescription : null,
				activetrackaction : null,
				activetracklabel : "",
				activetrack : {},
				trackstart:"",
				trackend:"",
				activechart : null,
				displayww:"",
				showmoredesc:false,
				tracks:[],
				alltracks:[],
				filteredtracks:[],
				customiconreorder:{
					icon: '<i class="fas fa-sort"></i>',
					index: 0,
					title: 'Reorder tracks',
					class: 'sort-tracks-icon',
					click: function (chart, options, e) {
						bus.$emit('reordersort')
					}
				},
				trackdates:{ formstart:{ status:0, value:"" }, formend:{ status:0, value:"" } , editstart:{ status:0, value:"" }, editend:{ status:0, value:"" }, milestones:{ status:0, value:"" } },
				dataoptions : {

					series: [],
					noData : {
						text: 'No available data',
						align: 'center',
						verticalAlign: 'middle',
						offsetX: 0,
						offsetY: 0,
						style: {
							color: undefined,
							fontSize: '15px',
							fontFamily: undefined
						}
					},
					states: {
						normal: {
							filter: {
								type: 'none',
								value: .15,
							}
						},
						hover: {
							filter: {
								type: 'darken',
								value: 0.80,
							}
						},
						active: {
							filter: {
								type: 'darken',
								value: 0.80,
							}
						},
					},
					chart: {
						id: 'masterplanchart',
						/*redrawOnParentResize: true,*/
						animations: {
							enabled: false
						},
						zoom: { enabled: true },
						height: '100%',
						width: '400',
						type: 'rangeBar',
						background: '#fff',
						toolbar: { show: true, offsetY: -5, offsetX: 0, tools: {

							download: false,
							selection: '<i class="far fa-search-plus"></i>',
							zoom: '<i class="far fa-search-plus"></i>',
							zoomin: '<i class="far fa-plus-circle"></i>',
							zoomout: '<i class="far fa-minus-circle"></i>',
							pan: '<i class="far fa-hand-paper"></i>',
							reset: '<i class="far fa-home-alt"></i>',
							customIcons: []
						},
						export:{}
					},
					events : this.chartEvents(),	          		
				},
				plotOptions: {
					bar: {
						barHeight: '50%',
						borderRadius: 0,
						horizontal: true,
						distributed: false,
						dataLabels: {
							hideOverflowingLabels: false,
							position: 'bottom'
						},
						colors: {
							backgroundBarColors:'#000',	
							backgroundBarOpacity: 1
						},
					}
				},

				tooltip: {
					enabled: false,
					custom: function({series, seriesIndex, dataPointIndex, w}) {

	      						//let newdate = new Date( series[seriesIndex][dataPointIndex] )

	      						//console.log( this. dataPointIndex )

	      						var rawlabel = w.globals.labels[dataPointIndex]
	      						let labels = rawlabel.split('x|x')

	      						return '<div class="p-2">' + labels[1] + '</div>'
	      					}
	      				},
	      				dataLabels: {
	      					textAnchor: 'start',
	      					enabled: true,
	      					offsetY: -5,
	      					offsetX: -35,
	      					formatter: this.datalabelformatter,
	      					style: {
	      						fontSize: '15px',
					    //fontFamily: 'Helvetica, Arial, sans-serif',
					    //fontWeight: 'bold',
					    colors: [this.tracktextcolor, '#fff']
					}
				},
				xaxis: {
					type: 'datetime',
					labels:{show:true, textAnchor: 'end'}
				},
				yaxis: {
					show: false, showAlways: false, floating: true,
					labels:{show:false, textAnchor: 'end'},
					axisBorder: { show: true }
				},
				legend: {
					show: false,
					showForSingleSeries: true,
					customLegendItems: ['Actual', 'Expected'],
					markers: {
						fillColors: ['#00E396', '#775DD0']
					}
				},
				fill: {
					opacity: 1,
				},

				grid: {
					borderColor: '#696f7b',
					row: {
						colors: ['transparent', 'transparent'],
						opacity: 1
					},  
					xaxis: {
						lines: {
					      //show: true
					  }
					}
				},
			},
				//
				datepickerstate : 0,
				showclosedplan : false,
				explainvalues : false,
				closingremarks : "",
				closingvalue : null,
				closingplan : false,
				proximityplan:"",
				dtepicker: { disableMobile:true, maxDate:'', minDate:null, altFormat: 'Y-m-d', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
				selectedforexport : { 'type':'ppt', 'label':"Project Master plan (DK)", 'key':"dk1" } ,
				exporttemplates : [ { 'type':'ppt', 'label':"Project Master plan (DK)", 'key':"dk1", "disabled":true } , { 'type':'ppt', 'label':"Project Master plan (EN)", 'key':"en1", "disabled":true  }, { 'type':'doc', 'label':"Project Master plan (DK)", 'key':"dkword1", "disabled":true  }, { 'type':'doc', 'label':"Project Master plan (EN)", 'key':"enword1", "disabled":true  }, { 'type':'xls', 'label':"All master plan data", 'key':"enxls1", "disabled":false  } ],
				
				width: 100,
				height: 0,
				top: 0,
				left: 0,
				isdragging: false,
				tracks : [],
				plans : [],
				closedplans : [],
				activetrack : {},
				tempplan : {},
				focusplan : {},
				
				textcontact:"",
				textemail:"",
				windowsize:0,
				showtooltip:true,
				showpopover:false,
				nofade:true,
				sortedplans:[],
				mode:"idle",
				confirmplan:false,
				mouseovertimer:0,
				mouseovert : null,
				addnewtrack: false,
				addanother: false,
				//boardusers : [],
				editlabel: false,
				editdescription: false,
				editaction:false,
				exportloading: false,
				exportloading2: false,
				imgbody : null,
				touchevent : "",
				touchtimer : null,
				apidomain:"",
				chartwidth:850,
				watchtimer:null,
				descel:null
			}
		},

		props:{
		},

		watch:{

			filteredtracks : function( e, ev ){

				if( e.length == 0 ){
					this.exporttemplates = [ { 'type':'ppt', 'label':"Project Master plan (DK)", 'key':"dk1", "disabled":true } , { 'type':'ppt', 'label':"Project Master plan (EN)", 'key':"en1", "disabled":true  }, { 'type':'doc', 'label':"Project Master plan (DK)", 'key':"dkword1", "disabled":true  }, { 'type':'doc', 'label':"Project Master plan (EN)", 'key':"enword1", "disabled":true  }, { 'type':'xls', 'label':"All master plan data", 'key':"enxls1", "disabled":false  } ]
				}else{
					this.exporttemplates = [ { 'type':'ppt', 'label':"Project Master plan (DK)", 'key':"dk1", "disabled":false } , { 'type':'ppt', 'label':"Project Master plan (EN)", 'key':"en1", "disabled":false  }, { 'type':'doc', 'label':"Project Master plan (DK)", 'key':"dkword1", "disabled":false  }, { 'type':'doc', 'label':"Project Master plan (EN)", 'key':"enword1", "disabled":false  }, { 'type':'xls', 'label':"All master plan data", 'key':"enxls1", "disabled":false  } ]
				}
			},

			'activetrack.label' : function( e, ev ){

				if( e == '' ){

					this.activetrack.label = ev
				}

			},

			/*addnewtrack : function( e ){
				if( e == true ){
					this.activetrack = {}
					this.showpopover = false
				}				
			},*/

			/*plans : function( e ){

				if( e.length == 0 ){
					this.activetrack = {}
				}

				this.sortedplans = e 
			},*/
			//activetrack : function( e ){

   				//this.updateboardusers()
			//},

			cockpit : function( e ){

				if( !e.masterplan ){
					//redir
					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("masterplan", "board")
					location.href=newroute
					//{{ cockpit.masterplanobjective._id }}
					//this.$router.push( 'board' )
				}
			}


		},

		computed:{


			numtracks(){

				return this.alltracks.length
			},

			

			editmindate(){


				let date = new Date(this.trackdates.editstart.value) 
				date.setDate(date.getDate() + 1);
				return date

			},

			formstartvalue(){

				return this.trackdates.formstart.value
			},

			formstartready(){

				let toreturn = true

				if( this.trackdates.formstart.value != "" )  {
					toreturn = false
				}


				return toreturn
				//console.log( trackdate , "before")
			},

			buttonVariant(){

				let toreturn = ' border-normal'

				if( this.datepickerstate == 1 ){
					toreturn = ' border-success'
				}
				if( this.datepickerstate == 2 ){
					toreturn = ' border-danger'
				}

				return toreturn
			},


			boardusers(){


				let teamids = []

				for( var i in this.cockpit.sharedtoteams ){
					teamids.push( this.cockpit.sharedtoteams[i].userid )
				}


				let boardusers = []

				if( this.team && this.cockpit.sharedtoteams && teamids.indexOf( this.team._id ) > -1){	       

					for( var i in this.team.sharedtousers ){

						//console.log( teamids, this.team.sharedtousers[i].userid , "lllllllllll")

						if( boardusers.indexOf( this.team.sharedtousers[i].userid ) == -1 ){
							boardusers.push( this.team.sharedtousers[i].userid )
						}

					}

				}

				return boardusers

			},

			allowsave(){

				let notallowed = 0 
				if( this.formtracklabel == '' ){
					notallowed += 1
				}

				if( this.trackdates.formstart.value == '' ){
					notallowed += 1
				}

				if( this.trackdates.formend.value == '' ){
					notallowed += 1
				}

				if( notallowed == 0 ){
					return true
				}else{
					return false
				}
			},
			
			canexport(){

				let toreturn = false

				if( this.tracks && this.tracks.length > 0 ){
					toreturn = true
				}

				return toreturn

			},

			canwrite(){

				let toreturn = false

				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()=='owner' || this.cockpit.myrole.toLowerCase()=='contributor')){
					toreturn = true
				}
				else if( this.cockpit && ( this.cockpit.myrole.toLowerCase()!='owner' && this.cockpit.myrole.toLowerCase()!='contributor')){
					toreturn =  false
				}

				if( !this.cockpit.isactive ){
					toreturn = false
				}
				
				/*if( this.isboardusers( this.loggedinuser._id ) ){
					toreturn = true
				}*/
				//toreturn  = false
				return toreturn
			},

			loggedinuser(){
				return this.$store.getters.user
			},

			team(){
				return this.cockpit.masterplan ? this.cockpit.masterplan : {}
			},

			singlecol(){

				if( this.windowsize < 1087 ){
					return 'narrow'
				}else{
					return 'wide'
				}

			},

			ismobile(){

				return this.$store.state.isMobile
			},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}else if( this.windowsize >= 461 && this.windowsize < 768 ){
					winsize = 'md'
				}else{
					winsize = 'lg'
				}

				return winsize

			},

			cockpitid(){
				return this.$route.params.cid
			},

			orgkey(){
				return this.$route.params.orgname ? this.$route.params.orgname : null
			},	

			token(){
				return this.$store.getters.token
			},

			cockpit(){
				return this.$store.getters.GetCockpit
			},

		}, 

		created() {
			window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
			window.removeEventListener("resize", this.windowOnresize);
		},

		mounted(){

			bus.$off('reordersort')
			bus.$on('reordersort', this.customiconsortclicked)

			bus.$off('setfullscreen')
			bus.$on('setfullscreen', this.setfullscreen)



			let self = this

			setTimeout( function(){

				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
				self.activechart.render();

			},10)	
			

			this.trackname = ""
			this.plandescription = ""



			setTimeout( function(){
				self.getalltracks()
				
			}, 50)

			setTimeout( function(){
				self.windowOnresize()
			},10)
			

		},

		filters: {
  			/*nl2br : function( str ){

  				if( str ){
  					return str.replace(/(\\r)*\\n/g, '<br>')
  				}else{
  					return str;
  				}

				

  			},*/

  			nl2br ( text ){

  				let toreturn = ""

  				if( text ){
  					toreturn = text.replaceAll("\n","<br>")
  				}

  				return toreturn
  			},
  		},

  		methods:{

  			filterdatatracks( datatracks ){


  				let toreturn = []

  				for( var i=0;i<datatracks.length;i++ ){

  					if( !datatracks[i].hidden ){
  						toreturn.push(datatracks[i])
  					}  					

  				}

  				return toreturn

  			},

  			filterhidden( tracks, ishidden ){

  				let toreturn = []

  				tracks.forEach( function( obj ){
  					if( ishidden === false && !obj.hidden  ){
  						toreturn.push( obj )
  					}

  					if( ishidden === true && obj.hidden ){
  						toreturn.push( obj )
  					}
  				})

  				return toreturn
  			},

  			handleOk(){

  				alert('')
  			},

			/*getel( ele ){

				//let el = null//document.getElementById(ele)

				//await el = this.getel('inputtrackdescriptionviewer')
				while (!this.descel) {
				  this.descel = document.getElementById(ele);
				}

			},*/

			isoverflowed( strtext ){

				let text = strtext

				let words = 0
				let newlines = 0
				//let chars = 0

				if( text ){
					//chars = text.length
					words = text.split(" ").length 
					newlines = text.split("\n").length 
					words = words + ( newlines * 8)
				}

				
				return words > 45 ? true : false
			},

			/*isdescriptionboxoverflown2(){

				let toreturn = false

				console.log( this.descel, "descel")

				//let el = null

				await this.getel('inputtrackdescriptionviewer')

				if( this.descel ){
					toreturn =  this.descel.scrollHeight != this.descel.clientHeight
				}				

				this.descriptionboxoverflown = toreturn
				this.showlessdesckey++

			},*/

			nl2br ( text ){

				let toreturn = ""

				if( text ){
					toreturn = text.replaceAll("\n","<br>")
				}

				return toreturn

			},

			setfullscreen(){


				this.activechart.updateOptions( { chart: { width : this.chartwidth  }}, false  )
				//window.dispatchEvent(new Event('resize'));
			},

			onsavedescription( activetrackdescription ){

				this.cancelevent = true
				this.updatetrack( 'description', activetrackdescription )

				/////this.isdescriptionboxoverflown()
			},

			onblursavedescription( activetrackdescription ){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.updatetrack( 'description', activetrackdescription )
						//this.cancelevent = true
					}

				}, 200)				

			},

			statushasbeenselected( e ){

				if( this.editdescription == true ){
					return false
				}

				this.statusselected = this.trackstatusitem(e.color)

				//console.log( e )
				//this.updatetrack('status', e.color )
			},

			milestonemodalshown(){

				let self = this

				setTimeout( function(){
					self.$refs['editmilestonesinput'].focus()
				}, 100)
				
			},

			milestonedatestatechanged( val ){

				if( this.editdescription == true ){
					return false
				}

				if( val == 2 ){
					clearTimeout(this.presavemilestonetimer)
					//this.presavemilestonetimer = null
				}

				this.milestonedatestate = val
			},

			dodatepickedmilestone( e ){

				if( this.editdescription == true ){
					return false
				}

				this.trackdates.milestones.value = e

			},

			dodatepickedstart( e ){

				if( this.editdescription == true ){
					return false
				}

				this.updatetrack('trackstart', e )
				this.trackdates.editstart.value = e

				if( new Date( this.trackdates.editstart.value ) >= new Date( this.trackdates.editend.value ) ){

					let date = new Date( this.trackdates.editstart.value )
					date.setDate(date.getDate() + 1);

					this.updatetrack('trackend', moment( date ).format('YYYY-MM-DD') )
					this.trackdates.editend.value = moment( date ).format('YYYY-MM-DD')
				}
			},
			dodatepickedend( e ){

				if( this.editdescription == true ){
					return false
				}

				this.updatetrack('trackend', e )
				this.trackdates.editend.value = e
			},

			trackssorted(){

				this.getalltracks()
			},

			updatetracksort(e){

				this.getTrack('')// = {}

				let self = this

				//let tracks = $('.sortedtracks', '#sortabletracks')

				let trackpos = 1

				let update = []

				$('.sortedtracks', '#sortabletracks').each(function (i,element) {

					let thistrack = self.alltracks.find( o => o._id === element.id);  
					if( element.id ){

						let tohidden = false

						if( thistrack.hidden ){
							tohidden = thistrack.hidden
						}

						update.push( { "trackid":element.id, "sortorder": trackpos+"", "hidden": tohidden } )
						trackpos += 1
					}
				});

				//self.updatetrack( 'sortorderall', trackpos, element.id )
				//let hiddentracks = $('.sortedtracks', '#hiddentracks');
				//let update = []



				// $('.sortedtracks', '#hiddentracks').each(function (i,element) {

				// 	if( element.id ){
				// 		update.push( { "trackid":element.id, "sortorder": "0", "hidden": true } )
				// 	}
					
				// });							

				Userapi.reOrderTracks( { "field":"reordertracks", "boardid": self.cockpitid, "t": self.token, "sortthis": JSON.stringify(update) } ).then( function(response){
					self.sorttracks = false
				})

				//
			},

			/*updatesort( e,i,o){

				//let el = $('#sortabletracks').get(0)

				let tracks = document.querySelectorAll('.sortedtracks')

				tracks.forEach(function (element) {
					  				console.log( element.id)
								});

				//toArray
			},*/

			customiconsortclicked(){

				this.sorttracks = true
			},

			enablesorttracks(){

				this.bindsortabletracks()

			},

			bindsortabletracks(){

				let sortingtracks = $('#sortabletracks').get(0)
				let hiddentracks = $('#hiddentracks').get(0)

				


				var delay = this.$store.state.isMobile ? 400 : 0;


				let self = this

				if( sortingtracks ){
					new Sortable( sortingtracks , {
						group: 'shared',
						animation: 150,
			         handle: ".draghandle",
			         delay:delay,
			         chosenClass:"shadow",
			         ghostClass:"ghostClass",
			         onMove:function(){

			         	//let sortedtrackslen = $('.sortedtracks', '#sortabletracks').length
			         	//let hiddentrackslen = $('.sortedtracks', '#hiddentracks').length


			         	//return sortedtrackslen <= 1 ? false : true

			         	return true
			         },
			         onRemove:function(){	         	


			         }
			          //onUpdate:function( event ){ 
			          // self.updatesort( event )
			          //},
			      });
				}

				if( hiddentracks ){

					new Sortable( hiddentracks , {
						group: 'shared',
						animation: 150,
						delay:delay,
						chosenClass:"shadow",
						ghostClass:"ghostClass",
					});
				}

				


			},

			exportwindowclosed( e ){
				
				//let self = this
				/*

				setTimeout( function(){
				//	self.$refs['initexportbutton'].blur()
				}, 800)

				setTimeout( function(){


				self.activechart.destroy();
				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
	        	self.activechart.render();


	        	self.getalltracks()

	        }, 100)*/

				/*self.activechart.destroy();
				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
	        	self.activechart.render();
	        	self.getalltracks()*/

				//location.reload();

			},

			datalabelformatter(val, opts) {

				let track = this.tracks[opts.dataPointIndex]
	          	return track.label// + " " + track.sortorder
	            //var rawlabel = opts.w.globals.labels[opts.dataPointIndex]
	            //let labels = rawlabel.split('x|x')
	            //return labels[1]
	        },

	        graphover: function(e,f,g) {

	        	e.target.style.cursor = '';

	        	let self = this

	        	let trackfound = 0

	        	for( let i in this.milestonemarkers ){

	        		this.milestonemarkers[i].style.strokeWidth = 10

	        		let clientX = e.layerX
	        		let clientY = e.layerY

	        		let x = this.milestonemarkers[i].getAttribute("x1")
	        		let y = this.milestonemarkers[i].getAttribute("y1")

	        		let found = 0
	        		let proximity = 9.0

					//console.log( this.milestonemarkers[i], "this")				

					//console.log( x, y, "xy")

					var isChrome = window.chrome

					if( isChrome ){
						clientX = clientX - 12.0
						clientY = clientY - 23.0
					}
					


					if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
						found += 1
					}
					if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
						found += 1
					}

					/*if( found < 2 ){

						found = 0

						clientX = clientX - 12.0
						clientY = clientY - 23.0


						if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
							found += 1
						}
						if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
							found += 1
						}

					}*/

					

					if( found > 1 ){

						e.target.style.cursor = 'pointer';
						this.milestonemarkers[i].style.strokeWidth = 16
						
					}

				}

				//console.log( e )
		        //do stuff
		        e.preventDefault();
		    },

		    graphclicked: function(e,f,g) {

		    	e.target.style.cursor = '';

		    	let self = this

		    	let trackfound = 0

		    	for( let i in this.milestonemarkers ){

		    		this.milestonemarkers[i].style.strokeWidth = 10

		    		let clientX = e.layerX
		    		let clientY = e.layerY

		    		let x = this.milestonemarkers[i].getAttribute("x1")
		    		let y = this.milestonemarkers[i].getAttribute("y1")

		    		let found = 0
		    		let proximity = 9.0

					//console.log( this.milestonemarkers[i], "this")				

					//console.log( x, y, "xy")

					var isChrome = window.chrome

					if( isChrome ){
						clientX = clientX - 12.0
						clientY = clientY - 23.0
					}
					


					if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
						found += 1
					}
					if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
						found += 1
					}

					/*if( found < 2 ){

						found = 0

						clientX = clientX - 12.0
						clientY = clientY - 23.0


						if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
							found += 1
						}
						if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
							found += 1
						}

					}*/

					

					if( found > 1 ){

						//console.log( this.milestonelabeltext, i )

						if( !this.milestonelabeltext[i] ){
							return false
						}

						let thislabeltext = this.milestonelabeltext[i].textContent
						

						let cntr = 0
						
						this.tracks.forEach(function (track) {
							track.milestones.forEach(function (mil) {
								if( mil._id == thislabeltext && trackfound == 0 ){
									trackfound += 1

									self.page = 'milestones'
				  					//self.getTrack(track._id)
				  					/*.then( function(){

				  						self.updatemilestone(mil._id)
				  					})*/

				  					self.activechart.toggleDataPointSelection( 0, cntr ) 

				  					setTimeout( function(){
				  						self.updatemilestone(mil)

				  					},100)
				  				}
				  			}); 

							cntr+=1
						});


						break;

						//e.target.style.cursor = 'pointer';
						//this.milestonemarkers[i].style.strokeWidth = 16
						
					}

				}

				//console.log( e )
		        //do stuff
		        e.preventDefault();
		    },

		    graphclickedv1: function(e,f,g) {

		    	if( 1 == 1 ){
		    		return false
		    	}

				//console.log( e, 'xxxxxxxxxxxxxx')

				if( this.editdescription == true ){
					return false
				}

				this.page = 'details'

				let self = this

				let trackfound = 0

				for( let i in this.milestonemarkers ){

					let clientX = e.layerX
					let clientY = e.layerY

					let x = this.milestonemarkers[i].getAttribute("x1")
					let y = this.milestonemarkers[i].getAttribute("y1")

					let found = 0
					let proximity = 8.0

					//console.log( this.milestonemarkers[i], "this")				

					


					var isChrome = window.chrome

					if( isChrome ){
						clientX = clientX - 12.0
						clientY = clientY - 23.0
					}

					//console.log( clientX,x, clientY, y ,"xyz")

					if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
						found += 1
					}
					if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
						found += 1
					}

					/*if( found < 2 ){

						found = 0

						clientX = clientX - 12.0
						clientY = clientY - 23.0

						//console.log( clientX, clientY, "recompute")



						if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
							found += 1
						}
						if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
							found += 1
						}

					}*/


					if( found == 2){

						if(  this.milestonelabelbox[i] ){
							
							let thislabeltext = this.milestonelabeltext[i].textContent

							/*this.activetrack.milestones.forEach(function (element) {
					  				console.log( element._id, thislabeltext , "ml")
					  			}); */

					  			let cntr = 0
					  			this.tracks.forEach(function (track) {
					  				track.milestones.forEach(function (mil) {
					  					if( mil._id == thislabeltext && trackfound == 0 ){
					  						trackfound += 1

					  						self.page = 'milestones'
					  					//self.getTrack(track._id)
					  					/*.then( function(){

					  						self.updatemilestone(mil._id)
					  					})*/

					  					self.activechart.toggleDataPointSelection( 0, cntr ) 

					  					setTimeout( function(){
					  						self.updatemilestone(mil)

					  					},100)
					  				}
					  			}); 

					  				cntr+=1
					  			}); 

							//this.milestonelabeltext[i].style.display = "block"
							//this.milestonelabelbox[i].style.display = "block"
						}
						

							/*if( this.activetrack ){
								this.activetrack.milestones.forEach(function (element) {
					  				console.log( element._id , "ml")
								}); 
								//= this.tracks[i]
							}*/

							found = 0

						}else{

							if(  this.milestonelabelbox[i] ){
								this.milestonelabeltext[i].style.display = "none"
								this.milestonelabelbox[i].style.display = "none"
							}

						}

					//console.log( found )

					//console.log( found , "lines")

				}

				//console.log( e )
		        //do stuff
		        e.preventDefault();
		    },


		    contextmenu: function(e) {

				//.log( e )
		        //do stuff
		        e.preventDefault();
		    },

		    sortorder( track , direction ){

		    	let neworderposition = 0

		    	if( direction == "up" ){
		    		neworderposition = track.sortorder != null ? track.sortorder + 1 : 0
		    	}else{
		    		neworderposition = track.sortorder != null ? track.sortorder - 1 : 0
		    	}

		    	this.updatetrack( 'sortorder', neworderposition )
		    },

		    openoutside( what ){

			//	if( what == 'custom' ){
				window.open( "/masterplan1?boardid="+this.cockpit._id )
			//		location.href=
			//	}
		},

		trackicon( status ){

			let toreturn = ''


			if( status == 'green' ){
				toreturn = 'fas fa-check'
			}
			if( status == 'yellow' ){
				toreturn = 'fas fa-exclamation'
			}
			if( status == 'red' ){
				toreturn = 'fas fa-times'
			}
			if( status == '' ){
				toreturn = ''
			}


			return toreturn
		},

		trackstatusitem( status ){

			let toreturn = {}

			this.statusoptions.forEach(function (element) {
					//console.log( element, element.color,)
					if( element.color == status ){
						toreturn = element
					}
				});

			return toreturn
		},

		trackstatus( status ){

			let toreturn = ''

			if( status == 'green' ){
				toreturn = 'All on-track'
			}
			if( status == 'yellow' ){
				toreturn = 'On-track, some milestones delayed'
			}
			if( status == 'red' ){
				toreturn = 'Not on-track'
			}
			if( status == '' ){
				toreturn = 'No status'
			}

			return toreturn
		},

		addmilestone(){

			this.milestonemodal = true

			this.activetrack.milestones.forEach(function (element) {
				element.editmode = false
			});


			this.addmilestoneeditmode = true

			this.editmilestonesid = ""
			this.editmilestones = ""
			this.editmilestonesdescription = ""

			this.statusselected = this.trackstatusitem('green')

			this.trackdates.milestones.value = moment( new Date() ).format('YYYY-MM-DD')
			this.iscriticalpath = false
			this.milestonesstatus='add'

		},

		presavemilestone(){

			let self = this

			let timer = 100

			if( this.milestonedatestate == 4 ){
				timer = 500
			}

			this.presavemilestonetimer = setTimeout( function(){

				self.savemilestone()
			}, timer )


		},

		savemilestone(){

				//console.log( this.statusselected )
				//return false

				let milestone = { "_id": this.editmilestonesid ? this.editmilestonesid : "generatenew", "status": this.statusselected.color, "label": this.editmilestones, "description": this.editmilestonesdescription ? this.editmilestonesdescription : "", "eventdate":this.trackdates.milestones.value,  "iscritical":this.iscriticalpath }

				if( milestone._id == 'generatenew' ){
					this.updatetrack( 'addamilestone', JSON.stringify(milestone) )
				}else{
					this.updatetrack( 'updateamilestone', JSON.stringify(milestone) )
				}

				this.canceleditmilestone()				

			},

			trydeletemilestone( mid ){

				this.milestonemodal = false
				this.updatetrack( 'removemilestone', mid )

			},

			updatemilestone( milestone ){

				//if( !this.canwrite ){
				//	return false
				//}

				this.milestonemodal=true

				this.addmilestoneeditmode = false

				this.editmilestonesid = milestone._id
				this.editmilestones = milestone.label
				this.editmilestonesdescription = milestone.description

				if( milestone.description ){
					this.editmilestonesdescription = milestone.description
				}
				

				this.trackdates.milestones.value = milestone.eventdate
				this.iscriticalpath = milestone.iscritical

				this.activetrack.milestones.forEach(function (element) {
					element.editmode = false
				});

				this.statusselected = this.trackstatusitem(milestone.status)

				milestone.editmode=true



			},

			canceleditmilestone( milestone ){

				this.milestonemodal=false

				this.addmilestoneeditmode = false

				this.activetrack.milestones.forEach(function (element) {
					element.editmode = false
				});

				this.milestonesstatus='idle'

			},

			addmilestonestotrack(){

				let milestone = { "label": this.editmilestones, "description": this.editmilestonesdescription, "eventdate":this.trackdates.milestones.value, "status":"", "iscritical":false }

				this.updatetrack( 'addamilestone', JSON.stringify(milestone) )

			},



			getTrack( trackid ){

				if( this.editdescription == true ){
					return false
				}

				let self = this


				this.editlabel = false

				function sortmilestones( a, b ) {
					if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
						return -1;
					}
					if ( new Date(a.eventdate) > new Date(b.eventdate) ){
						return 1;
					}
					return 0;
				}

				//tracksmilestones.sort( sortmilestones );
				this.lastaddedtrack = null
				//this.lastselectedtrack = trackid

				Userapi.Track( trackid, this.token ).then( function(response){

					if( response.data.ok ){
						self.activetrack = response.data.track

						let tracksmilestones = []

						if( self.activetrack.milestones ){

							tracksmilestones = self.activetrack.milestones
							tracksmilestones.sort( sortmilestones );

							/*tracksmilestones.forEach(function (element) {
							  element*.editmode = false
							});*/

						}

						//self.activetrack.milestones
						//tracksmilestones.sort( sortmilestones );

						self.activetrack.milestones = tracksmilestones

						self.trackdates.editstart.value = self.activetrack.trackstart
						self.trackdates.editend.value = self.activetrack.trackend
						self.activetrackdescription = self.activetrack.description


						//////self.isdescriptionboxoverflown()
						


						///self.statusselected = self.trackstatusitem(self.activetrack.status)

					}
				})



			},

			chartEvents(){

				let self = this


				return {

					/*
					markerClick : function(){ console.log('xx1') },
					legendClick : function(){ console.log('xx2') },*/
					/*click : function( event ){ console.log( event, 'xx') }, */
					beforeMount: function (chartContext, config) {
						setTimeout( function(){
							self.savemeacopy('upload')
						}, 1000)
					},
					dataPointSelection: function( event, chartContext, config ) {


						if( this.editdescription == true ){
							return false
						}

						let i = self.activechart.w.globals.selectedDataPoints
			      	//console.log( i[0] )

			      	if( i[0][0] != null ){

			      		let index = config.dataPointIndex

			      		self.lastselectedtrack = index
			      		if( self.tracks[index] ){
			      			self.getTrack( self.tracks[index]._id )
			      		}


			      	}else{

			      		self.activechart.toggleDataPointSelection( 0, self.lastselectedtrack ) 

			      	}

			      	

			      },
			      mounted: function (chartContext, options) {

			      	//console.log( "mounted")


			      	setTimeout( function(){


			      		self.savemeacopy()
			      	}, 100)

			      	/*setTimeout( function(){

			      		//alert('')

			      		//self.activechart.toggleDataPointSelection( 0, 3 ) 

			      		self.savemeacopy()
			      	}, 200)*/
			      	
			      },
			      animationEnd: function (chartContext, options) {
			      	alert('')
			      },
			      updated: function(chartContext, config) {

			      	//alert( self.lastaddedtrack )

			      	/*if( self.lastaddedtrack == null ){

			      		self.activechart.toggleDataPointSelection( 0, 0 ) 
			      		//self.lastaddedtrack = null
			      		
			      	}else{

			      		self.activechart.toggleDataPointSelection( 0, 3 ) 
			      		self.lastaddedtrack = null

			      	}*/

			      	self.graphworking = true
					//console.log( "updated", "strokeu")

			        //setTimeout( function(){

			      		//if( self.doupdatecriticalpath ){
			      			self.rendercriticalpath( function(){

			      				self.savemeacopy()
			      				self.graphworking = false

			      				$('.apexcharts-point-annotation-marker').hide()
			      			})
			      		//}

			      		//self.doupdatecriticalpath = false
			      		
			      		
			      	//}, 200)
			      },
			      beforeResetZoom: function () {
			      	self.lastzoom = null;
			      },
			      zoomed: function(_, value ) {
			      	self.lastzoom = [value.xaxis.min, value.xaxis.max];
			      }
			  }

			},

			savemeacopy( isupload , callback ){


				let self = this

				this.activechart.dataURI().then(({ imgURI }) => {
    				//self.$emit('ondumppng', imgURI)
    				self.savedpng = imgURI



    				if( isupload && isupload == 'upload' ){


    					var md5 = require('md5');

    					let base64image = imgURI
    					let nowstring = new Date().toString()

					            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

					            let imagekey = "image" + nowstring

					            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
					            var data = {
					            	Key: md5( imagekey  ) + ".jpg" , 
					            	Body: buf,
					            	ContentEncoding: 'base64',
					            	ContentType: 'image/jpeg'
					            };

					            s3Bucket.putObject(data, function(err, data){
					            	if (err) { 

					            		console.log(err);
					            		console.log('Error uploading data: ', data); 

					            	} else {

					                //console.log( "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" , 'successfully uploaded the image!');
					                //let graphimageurl = "<img src=\"https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" + "\" style=\"width: 300px;\" class=\"fr-fic fr-dib\">"

					                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + md5( imagekey  ) + ".jpg"

					               // console.log(graphimageurl, "uploaded");

					               self.uploadedgraphimage = graphimageurl

					               if( self.exportloading == false ){

					               	Userapi.savePlanPng( { "field":"savepng", "boardid": self.cockpitid, "t": self.token, "imgurl": graphimageurl } )
					               }


					               if( callback ){
					               	callback(graphimageurl)
					               }


					           }
					       })



					        }else{

					        	Userapi.savePlanPng( { "field":"savepng", "boardid": this.cockpitid, "t": this.token, "imgurl": imgURI } )
					        }

					    })

			},

			rendercriticalpath( callback ){

				let self = this

				let milestoneYs = []
				let milestoneXs = []

				let baroffset = '-6'

				if( this.exportloading == true ){
					baroffset = '-25'
				}


				let parent = document.getElementById('apexchartsmasterplanchart');


				if( !parent ){
					return false
				}

				let isrendered = parent.querySelector('#masterplancriticalpath')

		       //	if( !isrendered ){
		       	//	return
		       //	}

		       let rangebars = parent.querySelectorAll('.apexcharts-rangebar-area')

		       for( let i in rangebars ){
		       	if( rangebars[i] && typeof rangebars[i].hasAttribute === 'function' &&  rangebars[i].hasAttribute('barHeight') === true ){
		       		if( rangebars[i].hasAttribute('barHeight') === true ){
		       			rangebars[i].setAttribute('transform','translate(0,'+baroffset+')');
		       		}
		       	}

		       }

		       let element = parent.querySelector('.apexcharts-svg')

		       let goalgroups = parent.querySelectorAll('[className="apexcharts-bar-goals-groups"]');				

		       let hascritical = 0

		       let xypath = []


			//	alert( goalgroups.length , "strokeg")
			self.milestonemarkers = []

			for( let i in goalgroups ){


				let g = goalgroups[i]

					//el.addEventListener("touchstart", start);

					let lines = []

					let x = 0.0

					let y = 0.0
					

					if( typeof g.getElementsByTagName === 'function' ){
						
						lines = g.getElementsByTagName('line')


						for( let o in lines ){

							/**/

							//console.log( lines[o] )

							/*if( typeof lines[o].onmouseover === 'object' ){

							

								lines[o].addEventListener('onclick', function() {
									alert('')
								})

								lines[o].onmouseover = function(){
									alert("vv")
								}

							}*/

						//	console.log( typeof lines[o].onmouseover , "line")

							/*if( typeof lines[o].addEventListener === 'function' ){



								lines[o].addEventListener("mouseover", function(){
									alert('c')
								});


							}*/

							if( typeof lines[o].hasAttribute === 'function' ){

								//let stroke = lines[o].getAttribute("stroke")

								//strokeWidth

								//if( stroke == this.cpcolor){
								//	hascritical += 1
								//}else{

									let strokeww = lines[o].getAttribute("stroke-width")

									//alert( strokeww == 12 )

									if( strokeww == 12 ){
										hascritical += 1
									}
								//}

								

							}

						}

						

						for( let o in lines ){

							let x = null
							//lines[o].x1
							let y = null

							if( typeof lines[o].hasAttribute === 'function' ){

								/*lines[o].setAttribute("onclick", function(){

									alert('')
								})\\*/					


								let stroke = lines[o].getAttribute("stroke")

								 // if critical by color

									let pad = 18.0// 20.0 


									//if( goalgroups.length < 2 ){
									//	pad = pad + 9
									//}
									//else{}

									

									x = lines[o].getAttribute("x2")
									let y1 = lines[o].getAttribute("y1")
									let y2 = lines[o].getAttribute("y2")

									lines[o].setAttribute("y1", parseFloat(y1) + pad)
									lines[o].setAttribute("y2", parseFloat(y2) + pad)

									//console.log( y1, y2 ,"stroke")

									// set on wide
									/*if( self.exportloading == true ){
										lines[o].setAttribute("y1", parseFloat(y1) + 7.0)
										lines[o].setAttribute("y2", parseFloat(y2) + 7.0)
									}*/

									//lines[o].setAttribute("transform", "rotate(45)")

									//lines[o].setAttribute("x2", parseFloat(x) + 30.0)

									x = parseFloat(x) + 12
									y = parseFloat(y1) + 23.0

									/*if( self.exportloading == true ){
										//x = parseFloat(x) + 27.0
										y = parseFloat(y1) + 13.0
									}*/

									milestoneYs.push(y)
									milestoneXs.push(x)

									let strokeww = lines[o].getAttribute("stroke-width")

									//alert( strokeww == 12 )

									//if( strokeww == 12 ){
									//	hascritical += 1
									//}

									if( strokeww == 12 ){
										xypath.push( {x:x,y:y} )
									}	


								//if( stroke == this.cpcolor){
								//	xypath.push( {x:x,y:y} )
								//}								

								self.milestonemarkers.push( lines[o] )
							} 								
							
						}

						
					}
					
				}


				let cntr = 0
				let labely = null


			/*let circles = parent.querySelector('.apexcharts-point-annotation-marker')

			console.log( circles, "circles")

			for( let i in circles ){
				

				if( circles[i] && typeof circles[i].onclick == "function") {
					console.log( 'circle')
				}
			}*/


			let pointsannotations = parent.querySelector('.apexcharts-point-annotations')

			if( pointsannotations && typeof pointsannotations.getElementsByTagName === 'function' ){


				let Xs = []

				//



				let rects = pointsannotations.getElementsByTagName('rect')

				for( let i in rects ){
					if( rects[i] && typeof rects[i].hasAttribute === 'function'){
						
						rects[i].setAttribute("y", milestoneYs[i] )

						let y = rects[i].getAttribute("y")

						if( labely != y ){
							cntr = 0
						}

						// hide only labels on regular view


						if( self.exportloading == false){
							rects[i].style.display = "none"
						}else{

							if( i % 2 == 0 ){
								rects[i].setAttribute("y", milestoneYs[i] )
							}else{
								rects[i].setAttribute("y", milestoneYs[i] + 18.0 )
							}

						}
						
						//rects[i].style.display = "none"

						Xs.push( rects[i].getAttribute("x") )

						self.milestonelabelbox.push( rects[i] )

						cntr += 1
						labely = y
					}
				}				

				

			}


			let milestonelabels = parent.querySelectorAll('.apexcharts-point-annotation-label');	

			cntr = 0
			labely = null

			self.milestonelabeltext = []

			for( let i in milestonelabels ){
				if( milestonelabels[i] && typeof milestonelabels[i].hasAttribute === 'function'){
	       			//console.log( milestonelabels[i].textContent, "milestonelabels")

	       			//let alt = i % 2

	       			let y = milestonelabels[i].getAttribute("y")

	       			if( labely != y ){
	       				cntr = 0
	       			}

	       			milestonelabels[i].setAttribute("y", milestoneYs[i] + 14.0 )

	       			if( self.exportloading == false){
	       				milestonelabels[i].style.display = "none"	       				
	       			}else{

	       				if( i % 2 == 0 ){
	       					milestonelabels[i].setAttribute("y", milestoneYs[i] + 14.0 )
	       				}else{
	       					milestonelabels[i].setAttribute("y", milestoneYs[i] + 32.0 )
	       				}

	       			}

	       			//milestonelabels[i].style.display = "none"

	       			//console.log( milestonelabels[i].textContent)

	       			self.milestonelabeltext.push( milestonelabels[i] )

	       			cntr += 1
	       			labely = y
	       		}
	       		
	       	}

	       	function xycompare( a, b ) {
	       		if ( a.x < b.x ){
	       			return -1;
	       		}
	       		if ( a.x > b.x ){
	       			return 1;
	       		}
	       		return 0;
	       	}

	       	xypath.sort( xycompare );


	       	let path = null

	       	let lastx = 0.0
	       	let lasty = 0.0


	       	for( let o in xypath ){

	       		let x = xypath[o].x
	       		let y = xypath[o].y

	       		let ypadding = 25						

	       		if( path ){

	       			if( x != lastx ){
	       				path.push( "L "+ lastx + " " + ( y + ypadding) )
	       			}

					//ypadding = o == (xypath.length-1) ? 0 : ypadding

					path.push( "L "+ x + " " + ( y + ypadding) )
				}else{
					path = ["M "+ x + " " + ( y + ypadding) ]
					path.push( "L "+ x + " " + ( y + ypadding) )
				}

				lastx = x
				lasty = y

			}

			if( path ){

				var pathel = document.createElementNS("http://www.w3.org/2000/svg", 'path'); 
				pathel.setAttribute( "d", path.join(" ")); //Set path's data
				pathel.style.stroke = this.cplinecolor; //Set stroke colour
				pathel.style.fill = "none"
				//pathel.style.strokeDasharray="5,5"
				pathel.style.strokeWidth = "2px"; //Set stroke width
				pathel.setAttribute( "id", "masterplancriticalpath")
				

				//element.appendChild(pathel);
				element.insertBefore(pathel,element.firstChild);

				this.criticalpathrendered = true			

			}


			if(callback){
				callback()
			}



		},

		ondatekeyup( event, track ){

			if( event.keyCode != 13 ){
				track.status = 0
			}				

		},

		datepickerdone(){

				/*let self = this

				setTimeout( function(){

					self.$refs['datepickerbutton'].blur()
					//window.focus()
				}, 300)*/

			},

			isboardusers( userid ){

				let toreturn = false

				if( this.boardusers ){

					if( this.boardusers.indexOf( userid ) > -1){
						toreturn = true
					}

				}

				return toreturn
			},



			setforexport( template ){
				//this.selectedforexport.type = template.type
				//this.selectedforexport = template
				this.doexport(template.key, template.type)
			},

			mywidth( iid ){

				//addnewtrackbutton



				let el = this.$refs['flipshlabel_'+iid]

				if(  el && el[0] ){

					return el[0].scrollWidth + 'px'
					
				}

				
			},

			myleft( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el &&  el[0] ){

					return '-' + el[0].scrollWidth + 'px'
					
				}

				
			},


			/*doinitexportcsv(){

				let self = this

				self.exportloading = false

				Userapi.Exportplancsv( "", this.cockpitid, this.token ).then( function(response){					
					self.apidomain = response.data.apidomain
					//location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
				})

			},*/

			doinitexport(){

				let self = this

				this.exportloading2 = true
				//this.activetrack = {}

				this.doexportready = false
				setTimeout( function(){
					self.doexportready = true
				}, 1000)

				/*setTimeout( function(){


					let wideoptions = self.dataoptions



					//,

					//let chartheight = 100 + ( datatracks.length * 56 )

					//console.log( wideoptions.chart.height )

					wideoptions.chart.width = '1400px'
					wideoptions.plotOptions.bar.barHeight = '40%'
					wideoptions.dataLabels.offsetY = -25
			        //  offsetX: -35,
					//console.log( wideoptions.chart.height )

					self.activechart.destroy();
					self.activechart = new ApexCharts(document.querySelector("#masterplan"), wideoptions);
		        	self.activechart.render();

		        	self.getalltracks()

		        }, 100)*/



		    },

		    doexport( template, templatetype ){


		    	let self = this

		    	this.doexportready = false

		    	setTimeout( function(){ self.doexportready = true }, 2000)


		    	Userapi.Masterplangraphimage( self.cockpitid, "" , self.token, "export", template, "", templatetype ).then( function(response){

		    		if( response.data.ok ){

		    			location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
		    			setTimeout( function(){
		    				self.exportloading2 = false
		    			}, 200)

		    		}

		    	})

				/*self.savemeacopy( 'upload' , function( url  ){
					location.href = url
				})*/


				//if( self.exportloading == false ){


				/*	self.activechart.dataURI().then(({ imgURI }) => {
    				//self.$emit('ondumppng', imgURI)
    					Userapi.savePlanPng( { "field":"savepng", "boardid": self.cockpitid, "t": self.token, "imgurl": imgURI, "forexport":"yes", "template":template } ).then( function(response){
    						if( response.data.ok ){


    							location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
    							setTimeout( function(){
    								///ccccccself.exportloading = false
    							}, 200)

    							
    						}
						})

    				})
    				*/


				//}



				


			},

			

			canceledit(){

				//alert("cancel")

				this.cancelevent = true

				this.activetracklabel = this.activetrack.label
				this.activetrackdescription = this.activetrack.description
				//this.activetrack
				//this.activetrackaction = this.activetrack.mitigatingaction

				this.editlabel = false
				this.editdescription = false
			},

			doeditdescription(){

				if( !this.canwrite ){
					return false
				}

				this.cancelevent = false

				let self = this

				//let hh = $('#inputtrackdescriptionviewer').height()


				//let el = document.getElementById('inputtrackdescriptionviewer');

				/*if( el ){
					toreturn =  el.scrollHeight != el.clientHeight
				}	*/	
				this.showmoredesc = true
				this.editdescription = true
				this.editlabel = false
				this.editaction = false

				this.activetrackdescription  = this.activetrack.description

				setTimeout( function(){
					/////////////////////autosize($('.autogrow'));
					//$('#inputtrackdescriptioneditor').height(el.clientHeight+10)
					self.$refs.inputtrackdescription.focus()
					//self.activetrackdescription = null
				},50)

			},


			doeditlabel(){

				if( this.editdescription == true ){
					return false
				}

				let self = this

				this.editlabel = true
				this.editdescription = false
				this.editaction = false

				this.activetracklabel = this.activetrack.label


				setTimeout( function(){
					self.$refs.inputtracklabel.focus()
				},100)

				
			},

			
			closeotherbox( el ){


				this.activetrack = {}
			},

			isTruncated( iid ) {

				let el = this.$refs['shlabel_'+iid]

				if( el && el[0] ){
					return el[0].offsetWidth < el[0].scrollWidth
				}else{
					return false
				}

			},

			saveplan( sh ){

				//console.log( sh,  'saveplan')

				var updatevalue = { "t":this.token, "action":"updateplan", "field":'updateplan', "label":sh.label, "description":sh.description, "proximity":sh.proximity, "objectid": sh._id }

				Userapi.Updateplan( updatevalue ).then( function(response){

				})

			},

			ontracksupdated(){

				let self = this



				if( this.withtrackupdates > 0 ){


					this.getalltracks( function(){

						//console.log( , "wwwwwww")


						//self.activechart.w.globals.selectedDataPoints.forEach(function (element) {
								// self.activechart.toggleDataPointSelection( 0, element ) 
						//		});


							/*setTimeout( function(){

								self.activechart.toggleDataPointSelection( 0, 0 ) 
								self.activechart.toggleDataPointSelection( 0, 0 ) 

								//alert('')
							}, 3000)*/

						})




				}

				//if( this.plans ){
				//	this.onselected( this.plans[  this.plans.length - 1 ], false )
				//}
				
			},



			addnewtrackformhidden(){

				let self = this



				if( this.withtrackupdates > 0 ){


					this.getalltracks( function(){

						//console.log( , "wwwwwww")


						//self.activechart.w.globals.selectedDataPoints.forEach(function (element) {
								// self.activechart.toggleDataPointSelection( 0, element ) 
						//		});


							/*setTimeout( function(){

								self.activechart.toggleDataPointSelection( 0, 0 ) 
								self.activechart.toggleDataPointSelection( 0, 0 ) 

								//alert('')
							}, 3000)*/

						})




				}

				//if( this.plans ){
				//	this.onselected( this.plans[  this.plans.length - 1 ], false )
				//}
				
			},


			addnewtrackformshown(){

				this.withtrackupdates = 0

				let date = new Date()

				this.trackname = ""
				this.plandescription = ""
				this.formtracklabel = ""
				this.formtrackdescription = ""				


				this.trackdates.formstart.value = moment( date ).format('YYYY-MM-DD')

				date.setDate(date.getDate() + 30);

				this.trackdates.formend.value = moment( date ).format('YYYY-MM-DD')

				this.$refs.newtracknameinput.focus()

				/////////////////////autosize($('.autogrow'));


				//alert('')
			},


			sortplans(){

			},

			removeuserfromteam( userid, isforced , callback ){


				let self = this

				Teamsapi.Removeuser( this.token, this.team._id, userid, true, isforced, "checktracks" ).then( function( response ){

					if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey, "remove" ).then( function( response2 ){

								if( callback ){
									callback()
								}
							})
							
						}
						//Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
						//		self.getteams(response.data.team._id)					
						//})

					})

			},

			addusertoteam( userid , callback){


				let self = this

				Teamsapi.Adduser( this.token, this.team._id, userid ).then( function( response ){

					if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey , 'add').then( function( response2 ){	

								if( callback ){
									callback()
								}					
							//		self.getteams(response.data.team._id)					
						})

						}


					})

			},


			windowOnresize(){

				let self = this

				if( this.activechart ){

					this.windowsize = window.innerWidth

				//var element = document.getElementById('masterplanwrapper');
				//var positionInfo = element.getBoundingClientRect();
				//var height = positionInfo.height;
				//var width = positionInfo.width;

				//width = width < 100 ? 400 : width

				//self.displayww = width

				//this.activechart.updateOptions( { chart: { width : width }}, false  )

				setTimeout( function(){

					var box = document.getElementById("masterplanwrapper");

					let width = self.windowsize - 360 - 140


					box.style.width = width + 'px'

					/*if( self.windowsize > 1400 ){
						width = 1000
					}

					if( self.windowsize > 1600 ){
						width = 1200
					}*/

					if( self.windowsize < 769 ){
						width = self.windowsize - 40
					}

					if( self.windowsize < 400 ){
						width = 340
					}
					
					/*if( self.windowsize > (1400+100) ){
						width = 1100
					}else if( self.windowsize > (1400+200) ){
						width = 1200
					}else if( self.windowsize > (1400+300) ){
						width = 1400
					}*/

					/*if( self.windowsize > (1400+width) ){
						width = 1400
					}*/

					self.displayww = width + " " + self.windowsize

					self.activechart.updateOptions( { chart: { width : width }}, false  )

					

				}, 10)

			}



		},

		trydeletetrack(){

			this.confirmplan = true
		},



		confirmdeletetrack( track ){

			let self = this

			Userapi.Trackdelete( track._id, this.token ).then( function(response){



				if( track.owner._id ){
					self.removeuserfromteam( track.owner._id, false , function(){

					})
				}

				self.activetracklabel = ""
				self.activetrackdescription = ""
				self.activetrack = {}


					//self.activetrack = {}
					self.getalltracks()

					//self.activechart.toggleDataPointSelection( 0, 0 ) 
////////////////
}).finally( function(){

	self.confirmplan = false

})

},

ondatepicked( act , el ){

	console.log( this.$refs['edit-start-picker'].defaultValue , this.$refs['edit-start-picker'].value , 'picked')



				//if( moment(track.value, 'YYYY-MM-DD', true).isValid() ){

				//}

				
			},

			ondateselect( e ){

				/*if( e == "" ){
					this.updateplan( this.activetrack, 'proximity', "")
					this.datepickerstate = 1
					setTimeout( ()=>this.datepickerstate=0, 2500)
					return false
				}
				
				//alert(newdate)

				let self = this

				setTimeout( function(){

					let newdate = new Date(self.proximityplan)
					if( (newdate instanceof Date && !isNaN(newdate.valueOf())) == true ){
						self.updateplan( self.activetrack, 'proximity', self.proximityplan)

						self.datepickerstate = 1
						setTimeout( ()=>self.datepickerstate=0, 2500)
					}else{
						self.datepickerstate = 2
					}

				}, 100)*/

			},

			updateplan( sh, act, value, callback){


				var updatevalue = { "t":this.token, "action":"updateplan", "field":'updateplan', "label":sh.label, "description":sh.description, "proximity":sh.proximity, "objectid": sh._id }

				Userapi.Updateplan( updatevalue ).then( function(response){

				})
			},

			updatetrackowner( track, action, newdata ){
				

				if( action == "removeowner" ){
					this.updatetrack( 'owner', "", newdata.newid )
				}

				if( action == "addusertoboard" ){
					this.updatetrack( 'owner', newdata.newid )
				}

				if( action == "addowner" ){
					this.updatetrack( 'owner', "createnew", newdata.firstname )
				}

				if( action == "owneruser" ){
					this.updatetrack( 'owneruserfirstname', newdata._id , newdata.firstname )
				}

				//this.updatetrack( 'owner', newdata.newid )
			},

			updatetrack( field, value, value2 ){

				//if( this.cancelevent == true ){
					//this.cancelevent = false
				//	return false
				//}

				if( field=='label' ){
					if(value==""){
						return false
					}
				}


				function sortmilestones( a, b ) {
					if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
						return -1;
					}
					if ( new Date(a.eventdate) > new Date(b.eventdate) ){
						return 1;
					}
					return 0;
				}

				let objectid = this.activetrack._id

				if( field=='sortorder2' ){
					objectid = value2
				}

				let self = this
				let updatevalue = { "t":this.token, "objectid":objectid, "action":"updatetrack", "field":field, "newvalue":value }

				if( value2 ){
					updatevalue.newvalue2 = value2
				}

				Userapi.Updatetrack( updatevalue ).then( function(response){

					self.cancelevent == false

					if( response.data.ok && response.data.ok == true ){

						self.editlabel = false
						self.editdescription = false

						self.editmilestones = ""
						self.editmilestonesdescription = ""
						self.trackdates.milestones.value = ""
						self.trackdates.milestones.status = 0
						self.iscriticalpath = false
						self.milestonesstatus = "idle"

						if( response.data.track ){
							self.activetrack = response.data.track

							let milestones = []

							if( self.activetrack.milestones ){
								milestones = self.activetrack.milestones
								milestones.sort( sortmilestones );
							}

							self.activetrack.milestones = milestones					

						///self.statusselected = self.trackstatusitem(self.activetrack.status)
						
					}




					if( field == "owner" ){

						if( value == "" ){

							self.removeuserfromteam( value2, false , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})

						}else{

							self.addusertoteam( value , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})
						}

						

					}				

					

					//self.activetrackdescription = ""
					//self.activetracklabel = ""
					
					self.getalltracks()



				}







			})
			},

			unselect(){
				this.activetrack = {}
			},


			onselected( sh , showinfo, isforced, shouldfocus ){	


			},




			getalltracks( callback ){


				function sortmilestones( a, b ) {
					if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
						return -1;
					}
					if ( new Date(a.eventdate) > new Date(b.eventdate) ){
						return 1;
					}
					return 0;
				}

				//tracksmilestones.sort( sortmilestones );



				/*let green = '#7ece7e'
				let red = '#ff9c9c'
				let yellow = '#ffe0a5'
				let blue = '#b5c3e3'*/

				let green = '#b5c3e3'
				let red = '#b5c3e3'
				let yellow = '#b5c3e3'
				let blue = '#b5c3e3'

				//



				let self = this

				//alert('')
				Userapi.Tracks( this.cockpitid, this.token, "open" ).then( function(response){


					self.alltracks = response.data.tracks

					let datatracks = self.filterdatatracks(response.data.tracks)

					self.filteredtracks = datatracks

					if( datatracks.length == 0 ){


						let nowyy = new Date().getFullYear()
						let lastyy = nowyy - 1

						datatracks = [
						    {
						        "_id": "one",
						        "boardid": "",
						        "description": "",
						        "hidden": false,
						        "label": "No tracks shown",
						        "milestones": [],
						        "owner": {},
						        "sortorder": 6,
						        "status": "",
						        "trackend": lastyy + "-1-1",
						        "trackid": "6",
						        "trackstart": lastyy + "-1-1",
						        "visible": true
						    },
						    {
						        "_id": "two",
						        "boardid": "",
						        "description": "",
						        "hidden": false,
						        "label": "",
						        "milestones": [],
						        "owner": {},
						        "sortorder": 7,
						        "status": "",
						        "trackend": nowyy + "-1-1",
						        "trackid": "7",
						        "trackstart": nowyy + "-1-1",
						    }
						]
					}

						//this.getallplans
						/*
						{
                x: 'Analysis',
                y: [
                  new Date('2019-02-27').getTime(),
                  new Date('2019-03-04').getTime()
                ],
                fillColor: '#008FFB'
            }*/

            let newseries = []
            let xpoints = []

            if( datatracks ){						

            	self.tracks = datatracks					

            	let mintrackstart = new Date()
            	let maxtrackend = new Date()

            	for( let i in datatracks ){

            		let currentd = new Date( datatracks[i].trackstart )

            		if( currentd < mintrackstart ){
            			mintrackstart = currentd
            		}
            	}

            	let trackcntr = 0

            	for( let i in datatracks ){

            		let milestones = []

                				let xaxi = datatracks[i]._id//+'x|x'+datatracks[i].label

                				if( datatracks[i].milestones ){


                					let tracksmilestones = datatracks[i].milestones

                					tracksmilestones.sort( sortmilestones );

									/*tracksmilestones.forEach(function (element) {
									  element.editmode = false
									});*/

									for( let o in tracksmilestones ){                						

										let dotcolor = "#696f7b"
										if( tracksmilestones[o].status ){

											/*
											yellow := "976e1e"
								red := "b83838"
								green := "157815"
								*/

								if( tracksmilestones[o].status == 'yellow' ){
									dotcolor = "#b88e3d"
								}
								if( tracksmilestones[o].status == 'red' ){
									dotcolor = "#b83838"
								}
								if( tracksmilestones[o].status == 'green' ){
									dotcolor = "#2d932d"
								}
							}

										//dotcolor = tracksmilestones[o].iscritical ? self.cpcolor : dotcolor
										
										let dotstrokewidth = tracksmilestones[o].iscritical ? 12 : 10
										let dotstrokeshape = tracksmilestones[o].iscritical ? 'square' : 'round'

										//dotcolor = criticalcolor

										let milestone = { name: tracksmilestones[o].label, value: new Date( tracksmilestones[o].eventdate ).getTime(), strokeColor: dotcolor , strokeWidth: dotstrokewidth, strokeHeight: 0, strokeLineCap: dotstrokeshape}

										milestones.push(milestone)


                    					//let xpoint = []
                    					//+ ' - ' + tracksmilestones[o].eventdate


                    					let labelstyle = {
                    						background: '#fff',
									            //color: '#777',
									            fontSize: '15px',
									            fontWeight: 400,
									            //fontFamily: 'roboto'
									        }



									        xpoints.push( {  y:xaxi, x:milestone.value, marker: { size: 6, fillColor: "#000",
									        	strokeColor: "#000" }, label: {  borderColor: '#d5dae3', borderWidth: 1, text:tracksmilestones[o]._id ,  offsetY: 10000, style : labelstyle  } } )
									    }
									}

									let trackcolor = blue

									if( datatracks[i].status && datatracks[i].status == 'green' ){
										trackcolor = green
									}
									if( datatracks[i].status && datatracks[i].status == 'red' ){
										trackcolor = red
									}
									if( datatracks[i].status && datatracks[i].status == 'yellow' ){
										trackcolor = yellow
									}

									//datatracks[i].status
									let series = { x:'', y:[], fillColor: trackcolor, goals:milestones}

									series.x = xaxi



									let trackstart = new Date( datatracks[i].trackstart ).getTime()
									let trackend = new Date( datatracks[i].trackend ).getTime()

									if(  isNaN(trackstart) || isNaN(trackend) ){

										trackstart = mintrackstart.getTime()
										/*new Date()
										trackstart.setDate(trackstart.getDate() - 1);*/
										//trackstart

										trackend = new Date().getTime()
										series.fillColor = '#CD0000'

									}

								// if( (trackend - trackstart) === 0 ){

								// 	let now = new Date().getTime()
								// 	trackstart = now
								// 	trackend = now + ( 86400000 * 2)

								// 	series.y.push( trackstart )
								// 	series.y.push( trackend )

								// }else{
								// 	series.y.push( trackstart )
								// 	series.y.push( trackend )
								// }
								series.y.push( trackstart )
									series.y.push( trackend )
								

								/*series.events = { click: function(event, chartContext, config){ console.log(event, "ev"); }
							}*/


							newseries.push( series )


							if( self.lastaddedtrack == datatracks[i]._id){

									//alert('found')
									//alert(trackcntr)

									//self.activechart.toggleDataPointSelection( 0, trackcntr ) 

									
									    //	alert(trackcntr)
									}


									trackcntr += 1

								//xpoints.push({ x:xaxi, y:trackstart })
								//console.log( series, "series")
							}

							//self.dataoptions.chart.height = 100 + ( datatracks.length * 40 );

							////self.dataoptions.series = []
							////self.dataoptions.series.push( { name: 'Gantt', data : newseries } )
							////self.dataoptions.series.push( { name: 'Data 2', type: 'line', data : xpoints } )

							//console.log( newseries )

							/*

							Object { x: "62b1294e293cc1317c4ec5a6x|xHand Transforms not on end", y: 1647475200000 }*/

							/*self.dataoptions.annotations = {
								  points: [
							      {
							        x: "1647475200000",
							        y: "62b1294e293cc1317c4ec5a6x|xHand Transforms not on end",
							        marker: {
							          size: 6,
							      	},
							      	label: {
							      		text:'xxxxxxxxxxxxxxxxxxxxx'
							      	}
							      }]
							  }*/

							//console.log( mintrackstart )

							
							
							

						}

						

						setTimeout( function(){

							if( self.lastaddedtrack ){


								setTimeout( function(){

									self.activechart.toggleDataPointSelection( 0, (self.tracks.length - 1) ) 

								}, 100)



							}else{


								if( !self.activetrack._id && !self.lastaddedtrack && self.tracks.length > 0 ){

									setTimeout( function(){

										self.activechart.toggleDataPointSelection( 0, 0 ) 

									}, 100)

									

									/*self.activetrack = self.tracks[0]
									self.trackdates.editstart.value = self.activetrack.trackstart
									self.trackdates.editend.value = self.activetrack.trackend								

									*/

								}


							}


							

							if( self.activechart ){

/*

								let tracksmilestones = []

								if( self.activetrack.milestones ){

									tracksmilestones = self.activetrack.milestones
									tracksmilestones.sort( sortmilestones );
								}

								self.activetrack.milestones = tracksmilestones

								self.trackdates.editstart.value = self.activetrack.trackstart
								self.trackdates.editend.value = self.activetrack.trackend
								self.activetrackdescription = self.activetrack.description

								self.statusselected = self.trackstatusitem(self.activetrack.status)


								*/

								//rowheight

								let chartheight = 100 + ( self.tracks.length * 56 )

								//if( self.exportloading == true ){
								//	chartheight = 100 + ( self.tracks.length * 100 )
								//}


								//console.log( self.activechart.toolbar , "reorder")

								let inserticon = []

								if( self.canwrite ){
									inserticon.push( self.customiconreorder ) 
								}


								self.activechart.updateOptions( { chart: { height : chartheight , toolbar: { tools : { customIcons : inserticon}  } }, series: [{ name: 'Gantt', data : newseries }] }, false  )


								setTimeout( function(){

									self.activechart.updateOptions( { annotations : { position: 'front' ,
										xaxis: [{ x: new Date().getTime(), opacity: 0.3, strokeDashArray: 6,  borderColor: '#313338',
										fillColor: '#313338', }],
										points: xpoints
									}  }, false  )


								}, 50)


								if (self.lastzoom) {
									self.activechart.zoomX(self.lastzoom[0], self.lastzoom[1]);
								}

								//self.activechart.destroy();
								//self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
		        				//self.activechart.render();

		        			}


		        			if( callback ){
		        				callback()
		        			}


							//	alert('')
						},100)

						

						

					})




},

createnewtrack( addanother ){


	function sortmilestones( a, b ) {
		if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
			return -1;
		}
		if ( new Date(a.eventdate) > new Date(b.eventdate) ){
			return 1;
		}
		return 0;
	}


	let self = this

	if( this.formtracklabel != '' ){

		let newplan = { "label":this.formtracklabel, "description":this.formtrackdescription, "trackstart":this.trackdates.formstart.value, "trackend": this.trackdates.formend.value }

					//this.plans.push( newplan )

					this.trackname = ""
					this.plandescription = ""
					this.formtracklabel = ""
					this.formtrackdescription = ""

					//Newplan

					Userapi.Newtrack( { "t":this.token, "action":"new", "boardid":this.cockpitid, "label": newplan.label, "description": newplan.description, "trackstart":this.trackdates.formstart.value, "trackend":this.trackdates.formend.value } ).then( function(response){


						if( response.data.ok ){

							self.ontracksupdated()

							//self.tracks = []

							//self.plans = response.data.plans

							self.withtrackupdates += 1

							
							if( response.data.newtrack != "" && response.data.track ){

								self.activetrack = response.data.track

								let tracksmilestones = []

								if( self.activetrack.milestones ){

									tracksmilestones = self.activetrack.milestones
									tracksmilestones.sort( sortmilestones );

								}

								self.activetrack.milestones = tracksmilestones

								self.trackdates.editstart.value = self.activetrack.trackstart
								self.trackdates.editend.value = self.activetrack.trackend
								self.activetrackdescription = self.activetrack.description

								///self.statusselected = self.trackstatusitem(self.activetrack.status)


								//self.activechart.toggleDataPointSelection( 0, self.tracks.length ) 
							//alert('')

							self.lastaddedtrack = response.data.newtrack
						}



						if( addanother == false ){

							self.addnewtrack = false
						}else{

							setTimeout( function(){
								self.$refs.newtracknameinput.focus()								
							}, 300)
						}

					}




						//for( var i in self.plans ){

						//}

						/*setTimeout( function(){

							if( self.plans[ self.plans.length - 1 ] ){
								self.onselected( self.plans[ self.plans.length - 1 ] , true, true, false)
							}
								
						}, 1000)*/


						
						
						
						//console.log( response, "xxxxxxxx")
					})

				}

				//$('.tinydraggable').tinyDraggable();


			}
		},

		components:{ ownerPicker, vueselect }

	}
</script>