<script type="text/javascript"></script>
<template>

	<div>
			
		<TopBar :columnnumber = "2" hasdescription @showdescription="oncockpitdescription()">
            <template v-slot:title>
            {{ portfolio.name }}
            </template>


            <template v-slot:inactive v-if="!portfolio.active">
                <span class="ml-1 badge badge-light">Inactive</span>  
              </template>

            <template v-slot:description>
                <i class="infoicon description-icon far fa-info-circle fa-sm"></i>
            </template>

            <template v-slot:righticons>

            <base-icon-label class ="menu-icon" iconSize ="sm" icon ="fal fa-users"  labelafter ="Members" @click.stop.prevent="membersadminshow=true" />
            <base-icon-label v-if="canadmin" class ="menu-icon"  iconSize ="sm" icon ="fal fa-cog" @click.stop.prevent="doopensettings()"  labelafter =""/>

            </template>

            <template v-slot:righticonsdropdown>

            <a class="d-flex align-items-center statusopts">
                <div class="d-flex flex-row w-100" >
                    <base-icon-label class ="dropdown-item"  @click.stop.prevent="membersadminshow=true" icon ="fal fa-users"  labelafter ="Members"/>
                </div>
            </a>
            <a class="d-flex align-items-center statusopts">
                <div class="d-flex flex-row w-100" >                  
                    <base-icon-label v-if="canadmin"  class ="dropdown-item"  iconSize ="sm" @click.stop.prevent="opensettings=true" icon ="far fa-cog"  labelafter ="Settings"/>
                </div>
            </a>

            <a class="d-flex align-items-center statusopts">
                <div class="d-flex flex-row w-100" >                  
                    <base-icon-label v-if="canadmin"  class ="dropdown-item"  iconSize ="sm" @click.prevent="addprojecttothiscolumn" icon ="far fa-plus-circle"  labelafter ="Add project"/>
                </div>
            </a>

            </template>			
		</TopBar>    

            <portfolioSettingsmini @onhidden="settingsclosed" @oncloseme="closesettings" :opensettings="opensettings" @onsettingsopened="settingsopened" @onmakeinactive="makeinactive" ></portfolioSettingsmini>
                  
            <membersModal :subject="portfolio" :isactive="portfolio?true:false" :myrole="portfolio.active ? myrole : ''" :members="portfoliomembers" :checkforinvalidlink="false" :membersmodaltype="'portfolio'" :membersmodaleid="portfolio._id" :shown="membersadminshow" @onmodalhidden="membersModalHidden" :loggedinuser="loggedinuser" :token="token" @onmembersupdated="membersupdated"></membersModal>

            <b-modal no-fade v-model="showmakeactive" size="sm-p" no-close-on-backdrop no-close-on-esc>
            <template v-slot:modal-header>
              <h3 class="modal-title board_headeractivation">Make portfolio inactive</h3>
              <a class = "close-modal dialog-close-button">
                <i class="fal fa-times fa-lg " @click="showmakeactive=false"></i>
              </a>
            </template>

            <div v-if="portfolio.active">
                <p>You are about to make this portfolio inactive. This means that users will no longer have access to this portfolio.</p>
                <p>It is possible to make the portfolio active again - no data will be deleted.</p>
                <p>Are you sure you want to make this portfolio inactive?</p>
            </div>
            <div v-else >
                <p>The settings are not available because this portfolio is inactive. Would you like to re-activate it?</p>
            </div>

            <template v-slot:modal-footer="{ ok,cancel }">
              <b-button @click="showmakeactive=false">Cancel</b-button>      
              <b-button v-if="portfolio.active" class="btn-primary" @click="domakeinactive(false)">Make inactive</b-button>  
              <b-button v-else class="btn-primary" @click="domakeinactive(true)">Make portfolio active</b-button>             
            </template>
          </b-modal>

            <b-modal size="md" v-model="addProjectmodal" id="modal-addProjectmodal" title="add a project" @show="openpickaproject" hide-footer>

            <template v-slot:modal-header>
            <h3 class="modal-title">Add a project</h3>
            <a class = "close-modal dialog-close-bustaketton mr-2">
            <i class="fal fa-times fa-lg " @click.stop.prevent="addProjectmodal = false"></i>
            </a>
            </template>            

            <div>

                <div id="pickaproject">

  <b-tabs nav-class="d-flex flex-nowrap" content-class="mt-3" fill id="pickprojecttabs">
    <b-tab title="Select from existing projects" active class="p-2"> 

        <template>
        <vueselect id="addaprojecttoportfolio"  class="statuschooser mb-2" :searchable="true" :clearable = "false" :options="projects" :autoscroll="false" @input="projectPicked" v-model="selectedproject">
        <template #option="{ id, label }">
        <div class="vueselectitem p-1 pl-2">{{label}}</div>
        </template>
        <template #selected-option="{ id, label }">
        <div class="vueselectitem p-1 pl-2">{{label}}</div>
        </template>
        <template #selected-option-container="{ option, deselect, multiple, disabled }">
        <div class="vueselectitem p-1 pl-2 m-1">{{option.label}}</div>
        </template>       
        </vueselect>

        <hr class="mb-2">

        <div class="text-right">

            <button class="btn btn-secondary mr-2" href="#" @click.stop.prevent="addProjectmodal=false">Cancel</button>
            
            <button :disabled="!selectedproject._id" class="btn btn-primary" @click.stop.prevent="projectPickedSave()">Add project to portfolio</button>
        </div>    
        </template>       

    </b-tab>
    <b-tab title="Create a new project" class="p-2">
        
        <div>
                        <div class="mb-3">
                          <label class="form-label">Project name</label>
                          <input type="text" class="form-control" placeholder="Name your project" v-model="newprojecttext">
                        </div>

                        <div class="mb-3">

                          <label for="checkboxlabel" class="form-label">Select optional modules to include on your project. </label>
                          <div class="mb-2 d-flex align-items-center">

                            <div class="col-1 w-100 checkboxgroup">
                                <span>
                                <input type="checkbox" class="statusoptiondefaults" ref="check-defaultmasterplan" v-model="defaultmodules" value="masterplan">
                                <label id="checkboxlabel" for="checkbox" tabindex=3></label>
                                </span>
                            </div>

                            <div class="pt-2">Master plan</div>

                            </div>

                            <div class="mb-2 d-flex align-items-center">

                            <div class="col-1 w-100 checkboxgroup">
                                <span>
                                <input type="checkbox" class="statusoptiondefaults" ref="check-defaultstakeholders" v-model="defaultmodules" value="stakeholder">
                                <label id="checkboxlabel" for="checkbox" tabindex=3></label>
                                </span>
                            </div>

                            <div class="pt-2">Stakeholders</div>

                            </div>

                            <div class="mb-2 d-flex align-items-center">

                            <div class="col-1 w-100 checkboxgroup">
                                <span>
                                <input type="checkbox" class="statusoptiondefaults" ref="check-defaultrisks" v-model="defaultmodules" value="risk">
                                <label id="checkboxlabel" for="checkbox" tabindex=3></label>
                                </span>
                            </div>

                            <div class="pt-2">Risks</div>

                            </div>

                        </div>

                        <div class="mb-3">
                            
                          <label class="form-label">Add users to this project</label>
                          
                          <membersModalmini :subject="portfolio" :isactive="true" :myrole="'owner'" :members="portfolioteam" :checkforinvalidlink="false" :membersmodaltype="'portfolioteam'" :membersmodaleid="portfolio._id" :shown="true" @onmodalhidden="membersModalHidden" :loggedinuser="loggedinuser" :token="token" @onmembersupdated="portfolioteamupdated" :showtitle="''"></membersModalmini>

                        </div>

                        <hr class="mb-2">

                        <div class="text-right">

                            <button class="btn btn-secondary mr-2" href="#" @click.stop.prevent="addProjectmodal=false">Cancel</button>

                        <button class="btn btn-primary" :disabled="newprojecttext==''" @click.stop.prevent="docreateboard()">Create project and add to portfolio</button>

                        </div>

                        </div>



    </b-tab>
  </b-tabs>
</div>
                        <ul class="list-group" v-if="!pickproject && !createproject && 1==2">
                        <li class="list-group-item p-2"><a href="#" @click.stop.prevent="pickproject=true;createproject=false" class="">Select from existing projects</a></li>
                        <li class="list-group-item p-2"><a href="#" @click.stop.prevent="startanewproject()" class="">Create a new project</a></li>
                        </ul>                
                        
                    </div>

                    <template v-slot:modal-footer="{ cancel, ok }">

                        <button class="btn btn-secondary" href="#" @click.stop.prevent="addProjectmodal=false">Cancel</button>

                        

                        

                    </template>


            </b-modal>

              <cockpitdescription :shown="cockpitdescriptionmodalshown" @onmodalhidden="cockpitdescriptionmodalshown=false" @oncockpitupdated="boardupdated++" @onsavedescription="updatedescription"></cockpitdescription>

			<main class="main-wrapper cockpitview portfolio p-0 m-0" :class="{'expanded':!boardselected==false&&!ismobile}" >

            <div class="cockpits portfolio">

                <div class="d-flex flex-column flex-grow-1" id="phases" @click.stop.prevent="pageclick">

                <div class="d-flex p-2 subpage-toolbar" v-if="portfolio.groups && portfolio.groups.length > 0"> 
                    <div class="" v-if="!ismobile">

                        <button v-if="!canwrite" :disabled="true" class="btn btn-primary mr-2"><i class="fas fa-plus-circle"></i> Add project</button>

                        <button v-else :disabled="!portfolio.active " class="btn btn-primary mr-2" @click.stop.prevent="addprojecttothiscolumn()"><i class="fas fa-plus-circle"></i> Add project</button>
                    </div>
                    <div class="d-flex align-items-center ml-2" v-if="1==2">
                        Group:
                        <b-dropdown id="grouperby" :text="portfolio.grouper && portfolio.grouper.name ? portfolio.grouper.name : 'Select one'" class="noshadow ml-2">
                        <template v-for="(param,key) in portfolioforms"> 
                        <b-dropdown-item @click.stop.prevent="setparameterasgroup( param, true )"  :key="'grouperby_'+key" v-if="param.size=='selection' && ( portfolio.grouper && portfolio.grouper._id != param._id)">{{ param.name }}</b-dropdown-item>
                        </template>
                        <b-dropdown-item @click.stop.prevent="setparameterasgroup( {}, false )">No grouping</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="d-flex align-items-center ml-2 text-left flex-grow-1">
                        Swimlanes:
                        <b-dropdown :disabled="!portfolio.active" id="grouperby" :text="portfolio.grouper && portfolio.grouper.name ? portfolio.grouper.name : 'Select one'" class="ml-2" button-class="noshadow">
                        <template v-for="(param,key) in portfolioforms"> 
                        <b-dropdown-item @click.stop.prevent="setparameterasgroup( param, true )"  :key="'grouperby_'+key" v-if="param.size=='selection' && ( portfolio.grouper && portfolio.grouper._id != param._id)">{{ param.name }}</b-dropdown-item>
                        </template>
                        <b-dropdown-item @click.stop.prevent="setparameterasgroup( {}, false )">No grouping</b-dropdown-item>
                        </b-dropdown>
                    </div>

                    <div class="  ml-2 d-flex align-items-center" @click.stop.prevent="compactview()">
                        Compact view: <b-form-checkbox class="ml-2" v-model="viewcompact" switch></b-form-checkbox> 
                    </div>
                </div>

                


                <div class="gridview" v-if="portfolio.groups && portfolio.groups.length > 0">

                <div class="d-flex align-items-stretch columnn-headers">
                    <div class="mr-1 phase">
                        &nbsp;
                    </div>
                    <div class="d-flex align-items-stretch flex-grow-1 mr-1 phase phase-column" v-for="(group,key) in portfolio.groups" :key="'group_'+key" :class="{'compact':viewcompact}">
                            <div class="card-header mb-1 phase-header d-flex flex-grow-1">

                              <div class="">{{ group.name }}</div>
                                                      
                            </div>
                    </div>                  

                </div>

                
                <!-- style="height: 80vh; overflow-y: scroll;" -->
                <div  class="sectionview">
                    <div class="m-1 " v-for="(section,key) in portfolio.sections" :key="'section_'+key" > 
                        <div class="p-1" v-if="portfolio.sections.length > 1">
                             <a href="#" @click="sectionshowrow(section)" class="sectionshowrow">  <i v-if="!showsection(section)" class="fal fa-chevron-down"></i> <i v-else class="fal fa-chevron-up"></i> {{ section.name }} </a>
                         </div>
                        <div class="d-flex section" v-if="showsection(section)"> 

                        <div class="mr-1 phase phase-column bg-space1 p-1" v-for="(group,key) in section.groups" :key="'group_'+key" :class="{'compact':viewcompact}">                            

                                <template v-for="( board, key2 ) in group.boards">



                                 <portfolioProject  :key="'project_' + key + '_' + key2" :groupid="group._id" :group="group" :portfolioid="pid" @onprojectclicked="projectclicked" @onremoveproject="removeproject( activeboard._id, activegroup._id )" :portfoliodata="portfolio" :parametersdata="portfolioforms" :projectdata="board" :activeboardid="activeboard._id" :canwrite="canwrite" :viewcompact="viewcompact" ></portfolioProject >     

                                 </template>
                        </div>
                        </div>
                    </div>
                </div>

                </div>

                <div v-if="portfolio && portfolio.groups && portfolio.groups.length === 0" class="p-2">
                    No phases available
                </div>

                <i v-if="!portfolio" class="fa fa-circle-o-notch fa-spin m-auto" style="font-size: 18px;"/>

            </div>

            
            


            </div>  


                   

            </main>

            <div 
            v-show="boardselected"  
            id="sidebar" 
            class="border border-top-0 border-right-0 border-bottom-0" 
            :class="{'expanded':sidebarexpanded, 'mobile':ismobile }">  

                <ul class="nav nav-pills nav-flush mb-auto text-center mt-2" :class="{ 'expanded':sidebarexpanded, 'flex-column':( !canexpand || !sidebarexpanded )}">
                  <li class="apron-item" v-if="!sidebarexpanded">
                    <a style="height: 100vh;" href="#" class="nav-link py-2 rounded-0 sidebaritems" :class="{}" aria-current="page" title="Expand" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="expandsidebar(true)">
                      <i class="far fa-arrow-to-left"></i>
                    </a>
                  </li>
                  <li class="apron-item" v-if="sidebarexpanded">
                    <a href="#" class="nav-link py-2 rounded-0 sidebaritems" :class="{}" aria-current="page" title="Expand" data-bs-toggle="tooltip" data-bs-placement="right" @click.stop.prevent="expandsidebar(false)">
                      <i class="far fa-arrow-to-right"></i>
                    </a>
                  </li>
                </ul>

                <div v-show="sidebarexpanded" class="sidebarview" v-if="activeboard._id" >

                <div class="d-flex m-2">
                  <div class="mb-3 flex-grow-1">
                    <div class="modal-title mb-2" ><small class="badge badge-light" v-if="!activeboard.isactive">Inactive</small>  <h3>{{activeboard .name }}</h3> </div> 
                    <template v-if="activeboard.myrole==''">
                        <i class="fa-sharp fa-regular fa-link-simple-slash m-2"></i> No access to this project
                    </template>
                    <template v-else>
                        <template v-if="isediting">
                            <a href="#" class="actionprimary"><i class="fa-sharp fa-regular fa-link-simple m-2"></i> Open this project</a>
                        </template>
                        <template v-else>
                            <router-link class="actionprimary" :to="'/' + orgkey + '/boards/' + activeboard._id + '/objectives'"><i class="fa-sharp fa-regular fa-link-simple m-2"></i> Open this project</router-link>
                        </template>
                        
                    </template>
                    
                    </div>
                  <div class="" v-if="portfolio.active">
                    <b-dropdown id="dropdown-1" text="..." class="" toggle-class="text-decoration-none"  variant="link" no-caret>

                        <template #button-content><i class="far fa-ellipsis-v"></i></template>
                        <b-dropdown-item @click.stop.prevent="removeproject( activeboard._id, activegroup )">remove</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <div class="pr-1" style="direction: ltr;">
                <portfolioProjectmodal v-if="showprojectmodal" :board="activeboard" :portfoliodata="portfolio" :parametersdata="portfolioforms" :portfolioid="pid" :s3token="s3token" :canwrite="canwrite" @onformsaved="formsaved" @onchangeisediting="changeisediting" ></portfolioProjectmodal>
                </div>


                </div>

                
                
            </div>  
	</div>
</template>
<style type="text/css">

    .shorttab:not(.active) {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #pickaproject .nav-link:not(.active){
        border-bottom: solid 1px var(--N200);
    }


    #addaprojecttoportfolio .vs__actions{
        display: block !important;
    }

    /*body{
        overflow: hidden !important;
    }*/

    .sidebarglobal{
        background-color: #fff !important;
    }

    .sectionshowrow{
        color: #000;
    }

    .b-sidebar{
        width: 340px !important;
    }
    
    .cockpits.portfolio{
        min-height: 400px;
    }

    .groupbywrapper{

        width: 250px;
        position: absolute;
        /*border: solid 1px red;*/

    }
    .groupby{

        /*rotate: 270deg;*/
        transform: rotate(-90deg);
        transform-origin:top left;
        /*white-space: nowrap;*/
        /*  */
        /**/
       /* font-size: 15px;
        /*position: absolute;*/
        /*
    
    .groupby {
  white-space: nowrap;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center;
  transform-origin: top right;
  font-size: 15px;
  text-align: right;
  position: absolute;
  left: 50px;
  right: 50px;
  margin-left: 1px;
  width: 160px;
}
        */
    }

    #phases{
        overflow: hidden;
    }

    .section{
        /*min-height: 150px;*/
    }

    .gridview{
        min-height: calc(100vh - 110px);
        max-height: calc(100vh - 110px);
        overflow: auto;
    }

    .sidebarview{
        /*position: fixed; */
        
    }

    .cockpitview.portfolio.expanded{
        max-width: calc(100vw - 420px);
    }

    .cockpitview.portfolio.full{
        max-width: calc(100vw - 116px) !important;
    }

    .cockpitview.portfolio.fullmobile{
        max-width: calc(100vw - 36px) !important;
    }

    .cockpitview.portfolio.wide{
        max-width: calc(100vw - 61px) !important;
    }

    .cockpitview.portfolio.widemobile{
        max-width: calc(100vw) !important;
    }



    .gridview, .sectionview, .sidebarview, .b-sidebar-body{
        scrollbar-width: thin;
    }

    
    #sidebar.expanded{
        min-width: 340px;
    }

    #sidebar.expanded.mobile{
        width: 100% !important;
        max-width: none;
    }

    #sidebar{
        z-index: 950;
        background-color: #ffffff;        
        max-width: 340px;
        overflow: auto;
        scrollbar-width: thin;
        position: absolute;
        right: 0;
        top: 60px;
        min-height: calc(100vh - 60px);
        max-height: calc(100vh - 60px);
        overflow-y: scroll; 
        overflow-x: hidden;
    }

    html, .phase-column{
        scrollbar-width: thin;
    }  

    /*.phase-column:first-of-type {
        margin-left: 60px;
    }*/

    .first-column{
        margin-left: 30px;
    }

    .ghostClass{
        opacity: 0;
    }
    .bg-drop{
        /*background-color: var(--N200) !important;*/
    }
    .bg-space{
        /**/
    }

    .accent-right{
        background-color: var(--N100) !important;
        min-height: 80px !important;
    }

    .project{
        cursor: pointer;
    }

    .fw-bold{
        font-weight: bold;
    }

    .card-handle{
        cursor: move;
    }

    .cards-column{
        background-color: var(--N100);
        min-height: 200px;
    }

    .phase-column{
        min-width: 310px;
        max-width: 310px;
    }
    .phase-column.compact{
        min-width: 164px !important;
        max-width: 164px !important;
    }

    .card.project{
        max-width: 300px !important;
        min-width: 250px !important;
        min-height: 150px !important;
        padding-left: 40px;
        cursor: pointer;
        overflow: hidden;
    }

    .card.projectcompact{
        width: 164px !important;
        height: 80px !important;
        max-width: 164px !important;
        max-height: 80px !important;
        min-width: 164px !important;
        min-height: 80px !important;
        cursor: pointer;
        overflow: hidden;
    }

    .card.projectcompact h5{
        overflow: hidden;
        max-height: 35px;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    .projectfullcontents{
        min-height: 150px;
    }



</style>
<script>

    import * as Hammer from 'hammerjs'

	import {TopBar} from '@/components/topbar'
    
    import Cockpitsapi from '@/services/api/cockpits'

	import Portfolioapi from '@/services/api/portfolio'
	import vueselect from '@/views/tools/vueselect'	
	
    import {cockpitdescription} from '@/views/cockpit/components'



    import portfolioProject from '@/views/portfolio/portfolioProject'
    import portfolioProjectmodal from '@/views/portfolio/portfolioProjectmodal.vue'
    import membersModal from '@/views/cockpit/components/membersModal.vue'
    import membersModalmini from '@/views/cockpit/components/membersModalmini.vue'

    import portfolioSettingsmini from '@/views/portfolio/portfolioSettingsmini.vue'

    import {cockpitsettingsmodal} from '@/views/cockpit/components'

	import { v4 as uuidv4 } from 'uuid'

    import Sortable from 'sortablejs';

    import {bus} from '@/main'

    //addClass

	export default{
		name:'portfoliodetail2',
		props:{
		},

		data(){				      
	    	return {
                lanes:["one","two","three"],
                portfolio:{},
                temp:{},
                portfolioforms:[],
                name:"",
                error:"",
                selectedproject:{'label':'Select project'},
                boards : [],
                projects : [],
                pid:"",
                activegroup:{},
                portfolioProjectkey:[],
                excludes:[],
                phases:[],
                addProjectmodal:false,
                showprojectmodal:false,
                lastcolumn:null,
                activeportfolio:{},
                activeboard:{},
                tempactiveboard:{},
		        changes:0,
                updatedid:0,
                portfolioupdates:[],
                pendingmovegroup:{},
                membersadminshow:false,
                updatememberslist:0,
                //canwrite:true,
                //myactiverole: 'Owner',
                portfoliomembers: [],
                membersmodalshown : false,
                addtothiscolumn:{},
                swimlanedata:{},
                s3token:{},
                newprojecttext:"",
                working:false,
                sectionshowrows:[],
                viewcompact:false,
                defaultmodules:['masterplan','risk','stakeholder'],
                pickproject:false,
                createproject:false,
                portfolioteam:[],
                newcockpitid:"",
                pickproject:{},
                opensettings:false,
                cockpitdescriptionmodalshown:false,
                boardupdated:0,
                sidebarexpanded:false,
                canexpand:true,
                lastX:0,
                lastY:0,
                isediting:false,
                showmakeactive:false,
                toactive:false,
		    };
		},	
         /*beforeUnmount : {

            alert('')
         },*/
		 mounted() {

            document.title = 'Portfolio | Flowe'

            //alert('xc')
            //myDiv.setAttribute("style", "border-color:#FFFFFF;");
            //document.getElementsByTagName("body").style.overflow="hidden";
            //$('body').css('overflow','hidden')

            let self = this

            this.pid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

            setTimeout( function(){
                self.getportfolio(self.pid)
               // self.getboards()                

                Cockpitsapi.S3token().then( function( response ){

                    if( response.data ){
                        self.s3token = response.data
                    }
                    
                })
            },100)


            // set compact view
            //let viewcompact = false

            if( localStorage.viewcompact === 'true' ){
                this.viewcompact = true
            }else{
                this.viewcompact = false
            }

		 },

		watch:{
			
		},
		methods:{

            doopensettings(){

                if( this.portfolio.active ){
                    this.opensettings = true
                }else{
                    this.showmakeactive = true
                }
                
            },

            domakeinactive( isactive ){

                let self = this 

                let update = JSON.stringify( { "active":isactive } )

                Portfolioapi.updatePortfolio( { "portfolioid":this.portfolio._id, "patch":update, "t":this.token } ).then( function( result ){

                    if( result.data.ok ){
                       self.showmakeactive = false
                       
                       if( isactive ){
                        self.opensettings = true
                       }else{
                        self.getportfolio(self.pid)
                       }
                    }
                })

            },

            makeinactive(){

                this.showmakeactive = true
            },

            settingsopened(){
                //this.tempactiveboard = this.activeboard
                this.activeboard = {}
            },

            changeisediting( value ){
                this.isediting = value
            },

            expandsidebar(isopen){

                let expandlater = false

                if(!this.sidebarexpanded){                  

                    if( this.stagesizestate > 0 ){
                        expandlater = true
                    }

                    //this.compresseditorview()
                    
                }

                let self = this

                this.sidebarexpanded = isopen

                if( expandlater ){
                    this.$nextTick(function(){
                        //self.expandeditorview()
                    })
                }
                
            },

            updatedescription( name, description ){

                let self = this 

                let update = JSON.stringify( { "name":name, "description":description } )

                Portfolioapi.updatePortfolio( { "portfolioid":this.portfolio._id, "patch":update, "t":this.token } ).then( function( result ){

                    if( result.data.ok ){
                       //self.cockpitdescriptionmodalshown = false
                    }
                })
            },

            oncockpitdescription(){
              let self = this 

              bus.$emit('onopendescription', self.portfolio, self.loggedinuser, self.token, "Owner", 'portfolio', false, this.portfolio.active )

              //this.linktodescription()
              this.$nextTick(function(){
                self.cockpitdescriptionmodalshown = true
              })
            },

            
            onmodaldescriptionhidden(){
                //alert('y')

                //return true
            },

            settingsclosed(){

                let self = this

                //let temp = this.activeboard//.slice()
                //this.activeboard = {}

                setTimeout( function(){
                   //self.activeboard = self.tempactiveboard

                }, 100)
            },

            

            closesettings(){
                this.opensettings = false
                //this.activeparameter={}

                this.getportfolio(this.pid)


            },

            editsetting( parameter ){
                this.activeparameter = parameter
            },

            startanewproject(){

                this.createproject=true
                this.pickproject=false

               // this.loggedinuser

               let user = this.loggedinuser                

               let me = { "usertype":"user", "_id": user._id, "email": user.email, "firstname": user.firstname, "lastname": user.lastname, "name": user.firstname + ' ' + user.lastname, "orgrole": "Member", "orgstatus": "active", "profilepic": user.profilepic, "status": "active", "userexist": true, "role": "Owner", "rolekey": "0", "searchword": user.searchstring }

                this.portfolioteam.push(me)

            },

            portfolioteamupdated( value ){

                console.log( value , "xxxxxxxxx")

                /*if( value.action == 'removeteam' ){
                    return false
                }*/

                if( value.added ){

                    let team = value.selected

                    team.usertype = 'team'

                    /*let me = { "usertype":"team", "_id": user._id, "email": user.email, "firstname": user.firstname, "lastname": user.lastname, "name": user.firstname + ' ' + user.lastname, "orgrole": "Member", "orgstatus": "active", "profilepic": user.profilepic, "status": "active", "userexist": true, "role": "Viewer", "rolekey": "0", "searchword": user.searchstring }*/

                    this.portfolioteam.push(team)

                }

                if( value.removed ){

                   let newportfolioteam = []
                   this.portfolioteam.forEach( function( user ){                       

                        if( value.removed != user._id ){
                            newportfolioteam.push( user )
                        }                        
                   })

                   this.portfolioteam = newportfolioteam
                }

                

            },

            compactview(){

                this.viewcompact = this.viewcompact === true ? false : true

                localStorage.viewcompact = this.viewcompact
            },

            cancelsaveform(){


            },

            formvaluesupdated(){
                this.showprojectmodal = false
                this.activeboard = {}
                this.activegroup = {}


                this.getportfolio(this.pid)
            },

            showsection( section ){

                let toreturn = false

                let sid = ""

                sid = !section._id ? "unsorted" : section._id

                let sr = this.pid + '_' + sid
                let srindex = this.sectionshowrows.indexOf(sr)

                toreturn = srindex == -1 ? true : false

                if( this.portfolio.sections.length == 1 ){
                    toreturn = true
                }

                return toreturn
            },

            sectionshowrow( section ){

                let sr = this.pid + '_' + section._id

                let srindex = this.sectionshowrows.indexOf(sr)

                if( srindex == -1 ){
                    this.sectionshowrows.push( sr )
                }else{
                    this.sectionshowrows.splice(srindex, 1);
                }

                localStorage.sectionshowrows = JSON.stringify(this.sectionshowrows)


            },

            setparameterasgroup( parameter, doset ){

                let self = this

                Portfolioapi.groupby( { "portfolioid":this.portfolio._id, "parameterid":parameter._id?parameter._id:'', "doset":doset, "t":this.token } ).then( function( result ){

                    if( result.data.ok ){
                        self.getportfolio(self.pid)
                    }
                })

            },
            docreateboard(){

                

                //let tosubmit = { "name":this.newprojecttext, "modules": this.boarddefaults, "members": this.portfolioteam  }

                //console.log( tosubmit , "boardc")
                //return false

                let self = this

                var roles = []
                //var modules = []
                
                this.portfolioteam.forEach( function( user ){

                    let utype = 'user'

                    if( user && user.usertype ){
                        utype = user.usertype
                    }

                    roles.push( { "userid":user._id, "role":user.role, "usertype":utype } )
                })
                //roles = this.portfolioteam
               // roles.push(JSON.stringify( { "userid":this.loggedinuser._id, "role":"Owner"}))

                Cockpitsapi.Createnew( { "org":this.orgkey, "t":this.token, "cockpitdescription":"", "cockpitshortdescription":"", "cockpitname": this.newprojecttext, "modules": JSON.stringify(this.defaultmodules), "members":  JSON.stringify(roles) } )
                .then( function(response){

                    if( response.data && response.data.cockpit ){

                        //self.newcockpitid = response.data.cockpit._id
                        

                        /*self.$nextTick(function(){
                        //  console.log( response.data.cockpit , "zzzzzz")
                            bus.$emit('onboardcreated', response.data.cockpit, { "masterplan": self.defaultmasterplan, "stakeholders": self.defaultstakeholders, "risks": self.defaultrisks } )

                            self.newcockpitid = ''

                        })*/

                        setTimeout( function(){

                            let projectid = response.data.cockpit._id
                            self.addProjectToPortfoliogroup( projectid, self.pid )
                            self.newprojecttext = ""

                        }, 100)

                        
                    }

                })


            },
            addprojecttothiscolumn( group  ){

                this.getboards()

                /*let setgroup = {}
                if( !group ){
                    setgroup = this.portfolio.groups[0]
                }else{
                    setgroup = group
                }*/

                if( this.portfolio && this.portfolio.groups && this.portfolio.groups[0] ){
                    this.addtothiscolumn = this.portfolio.groups[0]
                    //this.addtothiscolumntext = group.name
                    this.addProjectmodal = true
                }

                
            },

            pageclick( ){

                if( this.isediting ){
                    let toview = document.getElementById(this.isediting)

                    if( toview ){
                        toview.classList.add("stillediting");
                        toview.scrollIntoView();
                    }
                    
                    return false
                }
                
                if( !this.activeboard._id ){
                    return false
                }
                

                this.activeboard = {}
                this.activegroup = {}
                this.showprojectmodal = false
            },

            groupbyname( data ){

                let item = data.parameter.options.find((item) => item._id === data.value);

                return item.name
            },

            boarddata( board ){

                /*let self = this

                Cockpitsapi.Cockpitschartdata( { "_id": board._id, "token": this.token } ).then( function(response){

                    console.log( response.data.status )

                    board = response.data.status
                    
                    
                })*/

                return board

            },

            membersModalHidden(){
                this.membersadminshow = false
            },
            membersupdated( members ){

                this.getportfolio(this.pid)

               // console.log( members )
                //alert('')
            },

            membersadminhide(){},
            membersadminshown(){},

            openpickaproject(){

                this.pickproject=false
                this.createproject=false
                this.portfolioteam = []
                this.newprojecttext = ""
                this.selectedproject = {'label':'Select project'}
            
                let user = this.loggedinuser                

               let me = { "usertype":"user", "_id": user._id, "email": user.email, "firstname": user.firstname, "lastname": user.lastname, "name": user.firstname + ' ' + user.lastname, "orgrole": "Member", "orgstatus": "active", "profilepic": user.profilepic, "status": "active", "userexist": true, "role": "Owner", "rolekey": "0", "searchword": user.searchstring }

                this.portfolioteam.push(me)

            },

            getprojects(){

                let toreturn = []

                if( this.boards ){

                    for(var i in this.boards){

                        let project = this.boards[i]
                        //console.log( this.excludes.indexOf( project._id ) , "xxx")
                        if( this.excludes.indexOf( project._id ) == -1 ){
                            project.label = project.name
                            toreturn.push(project)
                            //this.excludes.push( project._id )
                        }

                        
                    }
                }
                this.projects =  toreturn
            },

            startsearching(){

               //this.getboards()
            },

            changephase( projectid, fromgroup, togroup ){

                //this.pendingmovegroup = { "from":fromgroup, "to":togroup, "projectid":projectid }
                //console.log( togroup, fromgroup , projectid, "fromgroup, togroup, projectid")
                this.moveToGroup( fromgroup, togroup, projectid )
            },

            formsaved(){




                this.getportfolio(this.pid)

            },

            

            showprojectmodalshown(){},
            showprojectmodalhidden(){},

            projectclicked( project, groupid ){

                //alert(this.isediting)

                if( this.isediting ){
                    let toview = document.getElementById(this.isediting)

                    if( toview ){
                        toview.classList.add("stillediting");
                        toview.scrollIntoView();
                    }
                    return false
                }

                this.showprojectmodal = true               
                

                if( this.activeboard._id == project._id ){

                    this.activeboard = {}
                    this.activegroup = {}                    
                    this.showprojectmodal = false
                    this.sidebarexpanded = false
                    return false

                }else{

                    this.activeboard = {}
                    this.activegroup = {}
                    this.sidebarexpanded = true
                }


                let self = this

                this.$nextTick(function(){
                    self.activeboard = project
                    self.activegroup = groupid 

                    let sidebar = document.getElementById('sidebar');

                    if( sidebar ){

                        var hammertime = new Hammer(sidebar, {});
                        hammertime.on('swiperight', function(ev) {

                            self.activeboard = {}
                            self.activegroup = {}                    
                            self.showprojectmodal = false
                            self.sidebarexpanded = false
                            
                        });

                    }
                    
                })



                

            },
            projectclicked2( project, groupid ){

                let self = this   

                this.showprojectmodal = false                

                if( this.activeboard._id == project._id ){
                    
                    this.activeboard = {}
                    this.activegroup = {}
                    this.showprojectmodal = false
                    return false

                }else{

                    self.activeboard = project
                    self.activegroup = groupid  

                    setTimeout( function(){
                         self.showprojectmodal = true
                           
                    },10)

                    

                    //this.activeboard = {}
                    //this.activegroup = {}
                }
                //console.log( project )
               // return false

               

                //this.showprojectmodal = true  

               // self.activeboard = {}           

                //setTimeout(function(){
                                         
                //},100)

            },

            bindsortables(){

                if( !this.canwrite ){
                    return false
                }

                let self = this

                let phases = document.getElementsByClassName("cards-column")

                for(let i=0;i<phases.length;i++){

                    let sortable = new Sortable( phases[i], {
                        group: 'shared', // set both lists to same group
                        animation: 150,
                        chosenClass:"shadow",
                        ghostClass:"ghostClass",
                        handle: '.card-handle',
                        onStart:function( ev ){
                           // alert('')
                        },
                        onSort:function( ev ){
                            let portfoliosort = sortable.toArray()
                            //console.log( phasessort )

                            let update = JSON.stringify( { sortorder:portfoliosort, type:'portfolio' } )

                            Portfolioapi.updatesort( { patch:update, portfolioid:self.portfolioid, t:self.token, context:self.orgkey } ).then( function( result ){
                                    //post sort action
                                })
                        },
                        onEnd:function( ev ){

                            //console.log( ev.oldIndex, ev.newIndex, "evvv")

                            //self.moveToGroup( ev.oldIndex, ev.newIndex, ev.item.id )

                            $('.bg-drop').removeClass('bg-drop')

                        },
                        onAdd:function( ev ){

                            let fromcol = ev.from.id
                            let tocol = ev.to.id

                            self.moveToGroupSilent( fromcol, tocol, ev.item.id )                          

                            $('.bg-drop').removeClass('bg-drop')


                        },
                        onMove:function( ev ){

                            $('.bg-drop').removeClass('bg-drop')

                            ev.to.classList.add("bg-drop");

                        }
                    });
                }

               /* 
*/

            },

            addnewgroup(){

                let self = this

                let input = ""

                if( input = prompt('Phase name') ){

                    if( input != '' ){

                        Portfolioapi.addNewGroup( { uuid:uuidv4(), name:input, portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                            if( result.data.ok ){
                                self.getportfolio(self.pid)
                                //self.getboards()
                            }
                        })
                    }

                    

                }

                

            },

            removeproject( projectid, groupid ){


                let self = this
               // this.project._id, this.groupid 

                Portfolioapi.removeProject( { gid:groupid, projectid:projectid, portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){
                        self.getportfolio(self.pid)
                        self.activeboard = {}
                        self.activegroup = {}
                        
                    }
                })
            },

            

            moveToGroupSilent( fromgroup, togroup, projectid ){

                let self = this

                Portfolioapi.removeProject( { gid:fromgroup, projectid:projectid, portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                        Portfolioapi.addProject( { gid:togroup, projectid:projectid, portfolioid:self.pid, token:self.token, context:self.orgkey } )
                    }

                })


            },

            removeFromGroup( groupid, projectid ){

                let self = this

                Portfolioapi.removeProject( { gid:groupid, projectid:projectid, portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                        /*var index = self.excludes.indexOf(projectid);
                        if (index !== -1) {
                          self.excludes.splice(index, 1);
                        }

                        let newboards = []
                        self.portfolio.groups[groupid].boards.forEach( function( boardid ){

                            console.log( projectid, boardid )

                            if( projectid != boardid ){
                                newboards.push(boardid)
                            }
                        });

                        self.portfolio.groups[groupid].boards = newboards

                        self.portfolioProjectkey[groupid]++*/
                        
                    }

                    

                })
            },


            addProject(){

                this.addProjectmodal = true
            },

            projectPickedSave(){

                if( this.selectedproject._id ){
                    this.addProjectToPortfoliogroup( this.selectedproject._id, this.pid )
                }
                
                // this.projectPicked = {}
            },

            projectPicked( project){

                return false

                let self = this

                if( project && project._id ){

                    //this.projectPicked = project

                   

                }
            },

            addProjectToPortfoliogroup( projectid, pid ){

                this.addProjectmodal = false

                let self = this


                this.selectedproject = {'label':'Select project'}

                this.excludes.push(projectid)


                Portfolioapi.addProject( { groupid:this.addtothiscolumn._id, projectid:projectid, portfolioid:pid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok && result.data.portfolio ){

                        //self.portfolio.groups[0].push({})                        

                        /*self.portfolio.groups.forEach( function( group ){

                            if( group.default ){
                                console.log( result.data.project, "project" )
                                group.boards.push(result.data.project)
                            }
                            
                        })*/


                        //self.portfolio = {}
                        self.getportfolio(self.pid)
                    }

                })
            },

            getboards(){

                let self = this

                let excludes = this.excludes

                Portfolioapi.getboards( { excludes:excludes, token:this.token, orgkey:this.orgkey } ).then( function( result ){

                    if( result.data.ok && result.data.boards ){
                        self.boards = result.data.boards    

                        /*self.boards.forEach( function( board ){
                            self.projects.push( { "id":board._id, "label":board.name }  )
                        });    */            
                    }

                    setTimeout( function(){
                        self.getprojects()                        
                     }, 50)                   

                })
            },

            getportfolio( pid ){

                this.working = true

                let self = this

                this.sectionshowrows = []

                this.portfolio = {}

                Portfolioapi.getOne( { pid:pid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

                    if( result.data.ok && result.data.portfolio ){

                        /*if( ( result.data.portfolio && result.data.portfolio.myrole == '' ) || ( result.data.portfolio && result.data.portfolio.myrole == 'Viewer' && !result.data.portfolio.active ) ){
                            self.$router.push('/403')
                        }*/

                        self.portfolio = result.data.portfolio
                        self.portfolioforms = result.data.parameters

                        document.title = self.portfolio.name + ' | Flowe'

                        let excludes = []

                        if( self.portfolio.sections ){

                            for( var l=0;l<self.portfolio.sections.length;l++){

                                let section = self.portfolio.sections[l]   

                                if( section.showrow && section._id){
                                    self.sectionshowrows.push( pid + '_' + section._id )
                                }
                                
                                //alert(section.showrow)  

                                if( section && section.groups ){
                                    for( var i=0;i<section.groups.length;i++){      

                                        let group = section.groups[i]   

                                        if( group && group.boards ){     

                                            for( var j=0;j<group.boards.length;j++){

                                                let board = group.boards[j]   

                                                if( board._id ){
                                                    excludes.push( board._id )// = group.boards
                                                }

                                                //self.portfolioupdates[ board[j] ] = 0
                                            }

                                        }                      
                                        
                                    }

                                }

                           // self.phases.push(group.name)

                            }                       

                            

                            setTimeout( function(){
                                self.bindsortables()
                            }, 10)
                            

                        }

                        self.excludes = excludes

                        let sharedtousers = []
                        let members = []

                       if( self.portfolio.members && self.portfolio.members.users ){
                          members = members.concat(self.portfolio.members.users);
                       }

                       if( self.portfolio.members && self.portfolio.members.teams ){
                          members = members.concat(self.portfolio.members.teams);
                       }

                       self.portfoliomembers = members

                    /*
                      if( result.data.projects ){
                        
                        self.swimlanedata = {}

                        let gids = []
                        let groups = []

                        let parameters = result.data.parameters

                        let projects = result.data.projects

                        projects.forEach( function(board){

                             //console.log( board.group.id, "1111111111111" )

                            if( gids.indexOf( board.group.id ) == -1 ){
                                gids.push( board.group.id )

                                let group = board.group

                                group.boards = []

                                //let group = {}

                                //array1.concat(array2);

                                groups.push( group  )
                                
                            }

                        })


                        groups.forEach( function(group){

                            let boards = []

                            projects.forEach( function(board){


                                let item = { "id":board._id, "name":board.name }

                                if( board.group.id == group.id ){
                                 boards.push(item)
                                }

                            })

                            group.boards = boards

                        })                          

                        self.swimlanedata.groups = groups
                      }*/

                    }

                    self.working = false

                    if( !localStorage.sectionshowrows ){
                        localStorage.sectionshowrows = JSON.stringify(self.sectionshowrows)
                    }else{
                        self.sectionshowrows = JSON.parse(localStorage.sectionshowrows)
                    }                    

                }).catch(error => {

                    //this.$router.replace({ name: 'error403' });
                    //console.log(error); 
                    this.$router.push('/403?ref=getportfolio,' + pid + ',' + self.orgkey) 

                    Cockpitsapi.Logpage('pagefail', pid + ',' + self.orgkey )
                })
            },

            gotoSettings() {
              this.$router.push('settings')
            },

			dialogOpen: function (args) {
		       // args.cancel = true;
		    },

            onPhasemovestart( card ){

                console.log(card,"start")

            },
            onPhasemove( i,o,j ){
                console.log(i,o,j)


                /*Portfolioapi.removeProject( { groupid:groupid, projectid:projectid, portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                })*/

                /*
let self = this

                Portfolioapi.saveGroups( {"portfolioid":this.portfolioid, "groups":JSON.stringify(this.editopts), "token":this.token} ).then( function( result ){

                    self.addingtype = ""
                    //self.getportfolio(self.pid)
                    //self.getboards()
                    //self.getportfolio(self.pid)
                    //self.opensettings = false
                })
                */
            },

            getvalue( parameterid ){

                if( !this.activeboard || !this.activeboard.parametervalues ){
                    return false
                }

                //this.projectdata.parametervalues []

                let toreturn = ""

                let param = this.portfolioforms.find((item) => item._id === parameterid);
                let values = this.activeboard.parametervalues.find((item) => item.parameterid === parameterid);


                if( values ){

                    let tovalue = values.value

                    let tovalues = []

                    let x = tovalue.split(",")

                    x.forEach( function( o ){

                        if( o != '' ){

                            if( param.options && param.options.length > 0 ){

                                let option = param.options.find((item) => item._id === o);

                                if( option ){
                                    tovalues.push(option.name)
                                }else{
                                    if( values.parameter && values.parameter.options ){
                                        let option = values.parameter.options.find((item) => item._id === o);
                                        if( option ){
                                            tovalues.push(option.name)
                                        }
                                    }
                                }
                            }else{

                                tovalues.push(o)

                            }


                        }

                    })

                    toreturn = tovalues.join(", ")

                }                   

                /*this.portfolioforms.forEach( function( form ){
                    if( parameterid == form._id){
                    }
                    console.log( parameterid, form._id  , "xx")
                })*/

                return toreturn

            },
		},

		computed:{

			/*projects(){

				let toreturn = []

				if( this.boards ){

					for(var i in this.boards){

						let project = this.boards[i]

						//console.log( this.excludes.indexOf( project._id ) , "xxx")

						if( this.excludes.indexOf( project._id ) == -1 ){
							project.label = project.name
							toreturn.push(project)
						}

						
					}
				}

				return toreturn
			},*/

            boardselected(){
                return this.activeboard._id
            },

            canadmin(){
                return this.myrole == 'owner' ? true : false
            },

            canwrite(){

                let toreturn = ( this.myrole == 'owner' || this.myrole == 'contributor' ) ? true : false

                if( !this.portfolio.active ){
                    toreturn = false
                }

                if( this.isediting ){
                    toreturn = false
                }

                return toreturn
            },

            myrole(){

                let role = ""
                let isowner = 0
                let iscontributor = 0
                let isviewer = 0
                let me = this.loggedinuser._id

                if( this.portfolio && this.portfolio.members && this.portfolio.members.teams ){

                    this.portfolio.members.teams.forEach( function( team ){                        

                        let found = 0

                        team.sharedtousers.forEach( function( user ){
                            if( me == user.userid ){
                                found++                                
                            }                            
                        })

                        if( team.type == "everyone" || found > 0 ){
                            role = team.role
                        }

                        if( role.toLowerCase() == 'owner'){
                            isowner++
                        }
                        if( role.toLowerCase() == 'contributor'){
                            iscontributor++
                        }
                    })
                   
                }

                if( this.portfolio && this.portfolio.members && this.portfolio.members.users ){

                    this.portfolio.members.users.forEach( function( user ){
                        if( me == user._id ){
                            role = user.role
                            if( role.toLowerCase() == 'owner'){
                                isowner++
                               console.log('cc')
                            }
                            if( role.toLowerCase() == 'contributor'){
                                iscontributor++
                            }
                        }                            
                    })
                   
                }

                return  isowner > 0 ? 'owner' : ( iscontributor > 0 ? 'contributor' : role.toLowerCase() )
            },

            loggedinuser(){
                return this.$store.getters.user
            },

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

            ismobile(){
                //return true
                return this.$store.state.isMobile
            },

		},
		components:{ vueselect, membersModal, membersModalmini, portfolioProject, portfolioSettingsmini, portfolioProjectmodal, TopBar, cockpitdescription }
	}
	</script>