<template>
  <b-modal v-model="isshown" size="md" no-close-on-backdrop no-close-on-esc @hide="modalbeforehide" @hidden="modalhidden" @shown="modalshown"   hide-footer>
    <template v-slot:modal-header>
         <!-- <button v-if="!validforposting && !deviseDesktop" type="button" aria-label="Close" class="close" disabled style ="margin-left: auto auto auto 0;  padding:0; font-size:15px;"> Add indicator</button> -->
       
      <h3 class="modal-title"><span>Add status update</span></h3>
      <a class = "close-modal dialog-close-button mr-2">
      <i class="fal fa-times fa-lg fa-lg" @click="closeaddstatusmodal()"></i>
    </a>
    </template>
    <div> 
    <h4 class ="font-weight-bold">{{objective.name}}</h4>
    <div class ="diverder"></div>


    <div class="hide" v-if="objective.measuretype=='masterplan'">

      <div  id="risktable2">
        
        
        <p class="systemgen">
          <img :src="uploadedgraphimage" style="width: 600px;" class="fr-fic fr-dib" >
        </p>

        <p>&nbsp;</p>

        <h3 class="systemgen" id="statusheaderh3">Milestones</h3>

        <table style="width: 100%;" class="systemgen" id="statustable">              
          <tbody :key="key2" v-for="( cell, key2) in trackcells">
            <tr v-if="cell.row=='header'">
              <td colspan="3"><strong>{{ cell.label }}</strong></td>
            </tr>
            <tr v-else>
              <template v-if="cell.row=='item'">
                <td style="width: 20.000%;" nowrap>{{ cell.eventdate }}</td>
                <td style="width: 40.000%;">{{ cell.label }}</td>
                <td style="width: 15.000%;" :class="cell.color">{{ cell.status }}</td>
              </template>
              <template v-else>
                <td colspan="3">- no milestones -</td>
              </template>
              
            </tr>
            
          </tbody>
        </table>

        <p>&nbsp;</p>
        

      </div>

    </div>

    <div class="hide" v-if="objective.measuretype=='risk'">

      <div  id="risktable2">
        
        <p class="systemgen">
          <img :src="uploadedgraphimage" style="width: 600px;" class="fr-fic fr-dib" >
        </p>
        <table class="table w-100 systemgen" style="width:100%;" width="100%" id="statustable">
          <tr>
            <td width="40%" style="background-color: rgb(209, 213, 216);">Risk</td>
            <td width="40%" style="background-color: rgb(209, 213, 216);">Mitigating Action</td>
            <td width="20%" style="background-color: rgb(209, 213, 216);">Owner</td>
          </tr>
          <tr :key="risk._id" v-for="risk in risks">
            <td><strong>{{ risk.label }}</strong> <p v-if="risk.description" v-html="nl2br(risk.description)"></p></td>
            <td><p v-html="nl2br(risk.mitigatingaction)"></p></td>
            <td>{{ risk.owner.firstname }} {{ risk.owner.lastname }}</td>
          </tr>        
        </table>
      </div>

    </div>

    <div class="hide" v-if="objective.measuretype=='stakeholder'">

      <div id="risktable2">
        
        <p class="systemgen">
          <img :src="uploadedgraphimage" style="width: 600px;" class="fr-fic fr-dib" >
        </p>


        <table class="table w-100 systemgen" style="width:100%;" width="100%" id="statustable">
          <tr>
            <td width="30%" style="background-color: rgb(209, 213, 216);">Stakeholder</td>
            <td width="40%" style="background-color: rgb(209, 213, 216);">Description</td>
            <td width="30%" style="background-color: rgb(209, 213, 216);">Activities</td>
          </tr>
          <tbody :key="'quadrant'+key" v-for="(stakeholders,key) in sortedquadrants">
          <th v-if="stakeholders.values.length > 0">
            <td colspan="3"><h4>{{ stakeholders.label }}</h4></td>
          </th>
          <tr :key="stakeholder._id" v-for="stakeholder in stakeholders.values">
            <td><strong>{{ stakeholder.label }}</strong> <template  v-for="(contact,key) in stakeholder.contactslist"><br :key="'contact'+key">- {{ contact.firstname }} {{ contact.lastname }}</template></td>
            <td><p v-if="stakeholder.description" v-html="nl2br(stakeholder.description)"></p></td>
            <td><p v-if="stakeholder.activities" v-html="nl2br(stakeholder.activities)"></p></td>
          </tr>
          </tbody>     
        </table>
      </div>

    </div>
    

    <div class ="indicaterselecter" :class ="preselecter.color" v-if="!haserrors">

      <vueselect class="statuschooser" :searchable="searchable" :clearable = "false" v-model="preselecter" :options = "objectivestatusoptions" @option:selected="onoptionselected">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
        </template>
        <template #option="{ refid, color, icon, indexvalue, label, weight }">
          <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>
        </template>
         <template #selected-option="{ refid, color, icon, indexvalue, label, weight }">
          <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>
        </template>  
        <template v-if="1==2" #search="{ attributes, events }">&nbsp;
          <input
            v-if="searchable"
            maxlength="1"
            class="vs__search"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </vueselect>
    </div>

    <div class="card mt-1 mb-1 p-2" v-if="1==2">

      <div class="card-body">{{ gptresponse }}</div>
      
      <a href="#" @click.stop.prevent="makegptrequest">Generate a status</a>      
      
    </div>

    <div class = "align-self-center m-2" v-if="haserrors">

      <div sizem="sm" class="base-icon-label danger nav-icon asalink" @click="moreerr()">
        <span class="iconlabelbefore overflow-string iconlabel">Unable to determine status</span>
        <div class="icon-md  forcesm">
          <span><i v-if="!showerror" class="fa-do-not-enter far" aria-hidden="true"></i> <i v-if="showerror" class="fa-do-not-enter fas" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>




      <div class="form-group smt-2">
        <div id="statuscommenteditortoolbar"></div>

        <froala id="statuseditor" :config="froalaconfig" class="mb-0" :onManualControllerReady="initializefroala" v-model="message"></froala>
      </div>

    </div>

    <div class="form-group text-right">
      Note: Status updates cannot be changed once published.
      <p class="text-danger" v-if="addnewerror && addnewerror=='orgnoaccess'">Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists.</p>
      <div class = "mt-2 mb-2"> 
        <button-gruppe default="none" text1="Save and publish" :loading = "status == 'loading'" @no ="cancelloadautocomplete(); closeaddstatusmodal()" @yes="addnewindicator()" :disabled2="validforposting == false || imageuploading == true"></button-gruppe>
      
      </div>
    </div>


    <b-modal v-model="autosavecontentfound" modal-class="" size="md" no-close-on-backdrop no-close-on-esc scrollable>
    <template v-slot:modal-header>
          <h3 class="modal-title">Autosaved content found</h3>
          <a class = "close-modal dialog-close-button mr-2">
      <i class="fal fa-times fa-lg " @click="autosavecontentfound = false"></i>
    </a>        
    </template> 


    <p> We recovered autosaved content from a previous session.
Click ‘OK’ to load it, or ‘Cancel’ to discard it and start over. </p>


    <template v-slot:modal-footer="{ cancel, ok }">
      <button-gruppe @no ="cancelloadautocomplete()" @yes="loadautocomplete()"></button-gruppe>
    </template>

  </b-modal>

    

  <div ref="statustextgroup" style="display: none;">
    <p><img width="15px" :src="'https://app.flowe.io/static/img/' + selectedoption.color + '.png'" class="fr-fic fr-dii fr-fil">&nbsp;{{ selectedoption.label }}
      </p>
      <hr></div>

  </b-modal>
</template>

<style type="text/css">

  .fr-wrapper{
    scrollbar-width: thin;
  } 

  .sidebar-navbar > li{
    cursor: pointer !important;
  }

</style>

<style scoped>

.droptext {
    /*width: 265px !important;*/
  }

@media screen and (max-width: 400px) {
   .droptext {
    /*width: 265px !important;*/
  }
}

  
  
  
  .fr-view table.fr-no-border td, .fr-view table.fr-no-border th {
    border: none !important;
  }

  

</style>

<style type="text/css">

 .statuschooser .vs__search{
   /* visibility: hidden;*/
    height: 1px;
    opacity: 0;
  }
  
  .statuschooser .vs__dropdown-menu{
    /*border: solid 1px red !important;*/
  }

  /*.fr-alternate-rows tr:nth-child(odd){
    background-color: #4C8BF5;
    co*lor: #fff;
  }*/

</style>

<script type="text/javascript">
  
 /* function openai(){

    alert('xx')
  }*/

</script>
<script>

  import Userapi from '@/services/api/users'
  import Cockpitsapi from '@/services/api/cockpits'
  import vueselect from '@/views/tools/vueselect'
  import AWS from 'aws-sdk'

  const ID = process.env.VUE_APP_CLIENT;
  const SECRET = process.env.VUE_APP_CRET;

  // The name of the bucket that you have created
  const BUCKET_NAME = 'floweimage';
  AWS.config.update({region: 'eu-west-3'});

  const s3Bucket = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
    apiVersion: '2006-03-01', 
    region: 'eu-west-3',
    params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
  });

  export default {
    name:'addStatustoindicator',
    props:{
      shown:Boolean,
      cockpitid:String,
      token:String,
      objectivedata:Object,
      board:Object,
      deviseDesktop:Boolean
    },
    data(){
      return{
        forceclose:false,
        withChanges:false,
        selectedoption:{},
        gptprompt:"",
        gptresponse : "",
        stakeholders:[],
        tracks:[],
        searchable : false,
        haserrors : false,
        showerror : false,
        uploadedgraphimage:"",
        risks:[],
        riskaggregation:{},
        objective:{},
        preselecter:{ refid:"", label: 'Select status', color:null, icon:null, indexvalue:null, weight:null },
        indicatoroptions:[
          // {html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false}, 
          {html: '<i class = "far fa-check">', label: 'On track', value:'green', key:1.20, classed:'green',icon:true},
          {html: '<i class = "far fa-times">', label: 'Off track', value:'red', key:0.80, classed:'red', icon:true}
        ],
        isshown : false,
        showinstruction : false,
        targetvalue: "",
        realizedvalue: "",
        statusindicator : "",
        statustext : "",
        directvalue : 1,
        froalaconfig: {},
        linkInitControls: null,
        editorinit: false,
        message : '',
        autosavecontentfound : false,
        autosave : {},
        status: 'idle',
        calculatetimer : null,
        runningcalculations : false,
        imageuploading : false,
        addnewerror : ""
    }

  },
  watch:{

    shown : function( e ){
      this.isshown = e
    },
    
  },
  components:{ 
  vueselect     
  },

  computed:{

    sortedquadrants(){

      let quadrants = [0,1,2,3]

     // let sorted = []

      quadrants[0] = { label:"Manage closely", values:[] }
       quadrants[1] = { label:"Keep satisfied", values:[] }
        quadrants[2] = { label:"Keep informed", values:[] }
         quadrants[3] = { label:"Monitor", values:[] }

      this.stakeholders.forEach(function (stakeholder) {

        if( stakeholder.power >= 50 && stakeholder.interest >= 50 ){
          quadrants[0].values.push( stakeholder )
        }

        if( stakeholder.power >= 50 && stakeholder.interest < 50 ){
          quadrants[1].values.push( stakeholder )
        }
        
        if( stakeholder.power < 50 && stakeholder.interest >= 50 ){
          quadrants[2].values.push( stakeholder )
        }

        if( stakeholder.power < 50 && stakeholder.interest < 50 ){
          quadrants[3].values.push( stakeholder )
        }


      })

      let newquadrants = []

      //newquadrants = quadrants

      

      newquadrants.forEach(function (stakeholder) {

        stakeholder.values.forEach(function (value) {
            console.log( value.label , "vvvvvvvvvvvv")
        })
        
      })

      quadrants.forEach(function (stakeholder) {

        stakeholder.values.sort( function (a, b){
          console.log( (b.power * b.interest) , (a.power * a.interest) , "sortitem")
          return  (b.power * b.interest) - (a.power * a.interest)
        });

        newquadrants.push( { label:stakeholder.label, values: stakeholder.values }  )

      })

      newquadrants.forEach(function (stakeholder) {
        stakeholder.values.forEach(function (value) {
            //console.log( value.label , "vvvvvvvvvvvv")
        })
      })


      


      return newquadrants

    },

    ismobile(){

        return this.$store.state.isMobile
      },

    trackcells(){

      let statusoptions =  { "":"", green: "On-track" , black: "Done" , red : "Delayed" }
      
        
      let self = this

      let toreturn = []

      this.tracks.forEach(function (track) {

        if( !track.hidden ){


          toreturn.push( { "row":"header", "label":track.label }  )

          if( track.milestones && track.milestones.length>0 ){
             track.milestones.forEach(function (milestone) {
              toreturn.push( { "row":"item", "iscritical": milestone.iscritical ? 'On critical path':'', "color": milestone.status , "status": statusoptions[milestone.status] , "label":milestone.label, "eventdate":milestone.eventdate }  )
             })
          }else{
            toreturn.push( { "row":"norows", "label":"", "eventdate":"" }  )
          }

        }


      })

      return toreturn
    },

    organization(){
        return this.$store.state.organizations.selectedorganizations
      },

      validorg(){

        if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
          return false
        }else{
          return true
        }


      },

    orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      },

    objectivestatusoptions(){

      //var obj = this.objective.statusoptions

      var statusoptions = []

      $.each(  this.objective.statusoptions , function( i,o ){

        if( o.isdefault ){
          statusoptions.push(o)
        }

      })

      return statusoptions
    },

    userid(){
      return this.$store.state.user.userInfo._id
    },
    calculatedindexcolor(){
      return this.preselecter.color
    },
    calculatedindex(){
      return this.preselecter.indexvalue
    },

    validforposting(){
      if(typeof this.calculatedindex == 'number'){
        return true
      } else {
        return false
      }
    },

    cockpit(){
        return this.$store.getters.GetCockpit
      },

  },
   mounted(){     

      

      this.gptresponse = ""

        let self = this
        this.froalaconfig = this.$store.state.config

       // alert( this.froalaconfig.heightMax )
        this.froalaconfig.heightMax = 400
        this.froalaconfig.placeholderText = ''
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          }
          
        }

        $.getJSON( "https://s3token2.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data
          self.froalaconfig.fileUploadToS3 = data
        })
      
    },
  methods:{

    nl2br( text ){

      return text.replaceAll("\n","<br>")
    },

    closeaddstatusmodal(){

      if( !this.withChanges ){
        this.forceclose = true
      }

      this.isshown = false


    },

    modalbeforehide( modalev ){

      let self = this

      if( !this.forceclose ){
    
        modalev.preventDefault()
   
         this.boxOne = ''
              this.$bvModal.msgBoxConfirm('You have made changes that will be lost if you close without saving and publishing the status. Are you sure?', {
                title: 'Discard changes?',
                okTitle: 'Lose changes',
                cancelTitle: 'Keep editing',})
                .then(value => {

                  if( value == true ){

                    self.forceclose = true
                    
                    setTimeout( function(){
                      self.isshown = false
                    },200)

                    
                  }
                })
                .catch(err => {
                  
                }) 

      }    

        

      

    },

    getrgb( color ){

      let toreturn = ""

      if( color == 'green' ){
        toreturn = "color: rgb(21, 120, 21);"
      }
      if( color == 'red' ){
        toreturn = "color: rgb(150, 26, 26);"
      }
      if( color == 'yellow' ){
        toreturn = "color: rgb(151, 110, 30);"
      }
      if( color == 'blue' ){
        toreturn = "color: rgb(26, 49, 100);"
      }

       return toreturn

    },

    async makegptrequest(){

      let self = this


      let thisprompt = "make a status about " + this.cockpit.name + " " + this.objective.name + " " + this.gptprompt

      if( thisprompt != "" ){

        const CHAT_GPT_KEY = process.env.VUE_APP_OPENAI_KEY;
        const data = {
          model: 'gpt-4-1106-preview',
          prompt: thisprompt,
          max_tokens: 256,
          temperature: 0,
        };
        // Make the API call to ChatGPT
        const response = await fetch('https://api.openai.com/v1/completions', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${CHAT_GPT_KEY}`
          },
          body: JSON.stringify(data)
        });
        const { choices } = await response.json();
        // Insert the suggestion into selected text
        self.gptresponse = choices[0].text

      }

    },

    onoptionselected( option ){

      let self = this

      //console.log( option.color , "xxxxxxx")
      this.statustext = ""
      this.statustext = option.label

      this.selectedoption = option

      let editor = this.linkInitControls.getEditor()

      if( editor ){

        let htmlstr = this.linkInitControls.getEditor().html.get()

        const parser = new DOMParser();
        let existinghtml = parser.parseFromString(htmlstr, 'text/html');

        

        let h3tag = existinghtml.getElementById( 'statusheaderh3' );

        if( h3tag ){
          h3tag.remove()
        }

        let whitespacer = existinghtml.getElementById( 'whitespacer' );

        if( whitespacer ){
          whitespacer.remove()
        }
        

        

        let newhtml = existinghtml.body.innerHTML
        //let h3 = "<p id='statusheaderh3' contenteditable='false' class='fr-deletable'>" + option.label + "</p>"
        let h3 = "" 
        //"<div id='statusheaderh3'><h3>" + "<img src=\"https://app.flowe.io/static/img/" + option.color + ".png\" class=\"fr-fic fr-dii fr-draggable\">" + ' : ' + option.label + "</h3></div>"


       this.$nextTick().then(() => {
          h3 = self.$refs.statustextgroup.innerHTML;

          const box = "<div id='statusheaderh3'>" + h3 + "</div>"

          editor.html.set( box + '<p id="whitespacer"> </p>' + newhtml )


          // cursor to the end
          editor.selection.setAtEnd(editor.$el.get(0));
          editor.selection.restore();

       });

        //let h3 = this.$refs.statustextgroup

        
       // htmlDom.body.innerHTML

        /*     


        if( h3 ){
          h3.innerText = option.label
          this.linkInitControls.getEditor().html.set( existinghtml.outerHTML )
        }else{
          let html = "<h3 id='statusheaderh3'>" + option.label + "</h3>"
          this.linkInitControls.getEditor().html.insert(html)
        }
        */
       // 

        
        

      }

      

     // console.log()
    },

    onblurselect(){

      alert('')
    },

    moreerr(){

      if( this.showerror == true ){
        this.showerror = false
      }else{
        this.showerror = true
      }

    },

    sortbycxp( risks ){

      let sortedrisks = []


      for( let x in risks ){
        risks[x].cxp = risks[x].consequence * risks[x].probability
        sortedrisks.push( risks[x] )
      }

      //console.table(sortedrisks)

      sortedrisks.sort((a,b) => (a.cxp > b.cxp) ? -1 : ((b.cxp > a.cxp) ? 1 : 0))

//      console.table(sortedrisks)


      return sortedrisks

    },

    getdefaultselectvalue(){

      let self = this

      //var obj = this.objective.statusoptions

      let defcolor = null 

      /*if( self.objective.measuretype == 'risk' ){
          if( self.objective.aggregationmethod == 'worst' ){
            defcolor =  self.riskaggregation.worst
          }
          if( self.objective.aggregationmethod == 'best' ){
            defcolor =  self.riskaggregation.best
          }
          if( self.objective.aggregationmethod == 'average' || self.objective.aggregationmethod == 'averagelow' || self.objective.aggregationmethod == 'averagehigh' || self.objective.aggregationmethod == 'optimisticaverage' ){
            defcolor =  self.riskaggregation.averagelow
          }

      }*/

    // if( !defcolor || defcolor == "" ){

     // this.haserrors = true
     // this.showerror = true
    //}

      var defaultselected = {}

      $.each(  this.objective.statusoptions , function( i,o ){

        if( o.isdefault ){
          //statusoptions.push(o)


          if(o.color == defcolor){
            defaultselected = o
          }
         
        }



      })


      let empty = { html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false}

      return !defcolor ? empty : defaultselected
    },

    posonly( val){

      //@input="posonly($event)"

      //console.log( val.data , "cc")
    },

    cancelloadautocomplete(){

      this.autosave = {}
      Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
      this.autosavecontentfound = false
    
    },

    loadautocomplete(){

      this.message = this.autosave.content
      
      //Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
      //this.autosave = {}

      this.autosavecontentfound = false


    },
    pickvalue( newvalue ){

      this.directvalue = newvalue
    },

    initializefroala( initControls ){

    //  console.log(initControls,"")
      this.linkInitControls = initControls;

     // console.log( this.linkInitControls, "xxxxxxxxx111")
    },

    ping2(){
      alert("pong pong")
    },

    modalshown(){

      
        let self = this

        this.withChanges = false
        this.forceclose = false

        this.addnewerror = ""

        this.froalaconfig = this.$store.state.config

  
        this.froalaconfig.toolbarSticky =  false
        this.froalaconfig.toolbarStickyOffset = 0   
        this.froalaconfig.heightMin = 200
        this.froalaconfig.heightMax = "30dvh"

        let toolbar = ['undo', 'redo']
        this.froalaconfig.toolbarButtons.moreMisc.buttons = toolbar
        this.froalaconfig.toolbarButtons.moreMisc.buttonsVisible = 0

        
        this.froalaconfig.placeholderText = ''
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
           // alert('vv')
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          },
          'contentChanged' : function () {
            self.withChanges = true
          },
          'click': function ( clickEvent ) {

            /*let editor = self.linkInitControls.getEditor()
            var editorvalue = self.linkInitControls.getEditor().html.get()
            //console.log( editorvalue )

            var newdiv = document.createElement('div');

            newdiv.innerHTML = editorvalue

            let addasummary = newdiv.querySelector('.addasummary')

            newdiv.querySelector('.addasummary').remove()

            self.linkInitControls.getEditor().html.set( newdiv.innerHTML )

            if( addasummary ) {

              
              self.linkInitControls.getEditor().selection.setAtEnd(editor.$el.get(0));
              self.linkInitControls.getEditor().selection.restore();


            }*/


          },
        }

        $.getJSON( "https://s3token2.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data
          self.froalaconfig.fileUploadToS3 = data 
        })

       
      
      this.showinstruction = false
      this.statustext = ""
      this.imageuploading = false
      
      //document.title ='Add status update | Flowe'
      
      this.message = ''

      if( !this.editorinit ){

        this.linkInitControls.initialize()
          //this.editorinit = true



      }

      this.objectivesettings()
        
        
      },

      objectivesettings( callback ){

      let self = this

      Cockpitsapi.Objectiveedit( this.objectivedata._id,  localStorage.token, this.orgkey )
      .then( function(response){
        
       

        self.objective = response.data.measure

        
        if( response.data.autosave && response.data.autosave._id ){

          if( response.data.autosave.content != "" ){
            self.autosave = response.data.autosave
            self.autosavecontentfound = true
          }
        }

        self.message = ''


        if( response.data && response.data.ok ){



          if( self.objective.measuretype == "stakeholder" ){


          Userapi.Stakeholdergraphimage( self.cockpit._id, self.token ).then( function(response){
       
          if( response.data.ok ){

            self.stakeholders = response.data.stakeholders

            var md5 = require('md5');

            let base64image = response.data.graphimage
            let nowstring = new Date().toString()

            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

            let imagekey = "image" + nowstring

            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
            var data = {
            Key: md5( imagekey  ) + ".jpg" , 
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
            };

            s3Bucket.putObject(data, function(err, data){
              if (err) { 
                
                console.log(err);
                console.log('Error uploading data: ', data); 
              
              } else {


                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + md5( imagekey  ) + ".jpg"

                self.uploadedgraphimage = graphimageurl

                self.rendermessage()

                /*Userapi.Riskscomputed( "", self.objective._id , self.token ).then( function(response){

                  //if( response.data.ok ){
                    self.riskaggregation = response.data.aggregation
                    self.risks = self.sortbycxp(response.data.risks) 
                    self.preselecter = self.getdefaultselectvalue()
                    
                  //}

                }) */


              }
            })

          }else{
            self.preselecter = self.getdefaultselectvalue()
          }
            


          })


          
         }



         if( self.objective.measuretype == "masterplan" ){


          Userapi.Masterplangraphimage( "", self.objective._id , self.token, "status", "" , "" ).then( function(response){
       
          if( response.data.ok ){

            var md5 = require('md5');

            let base64image = response.data.graphimage
            let nowstring = new Date().toString()

            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

            let imagekey = "image" + nowstring

            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
            var data = {
            Key: md5( imagekey  ) + ".jpg" , 
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
            };

            s3Bucket.putObject(data, function(err, data){
              if (err) { 
                
                console.log(err);
                console.log('Error uploading data: ', data); 
              
              } else {


                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + md5( imagekey  ) + ".jpg"

                self.uploadedgraphimage = graphimageurl

                self.rendermessage()

                /*Userapi.Riskscomputed( "", self.objective._id , self.token ).then( function(response){

                  //if( response.data.ok ){
                    self.riskaggregation = response.data.aggregation
                    self.risks = self.sortbycxp(response.data.risks) 
                    self.preselecter = self.getdefaultselectvalue()
                    
                  //}

                }) */


              }
            })

          }else{
            self.preselecter = self.getdefaultselectvalue()
          }
            


          })


          
         }

        if( self.objective.measuretype == "risk" ){

          Userapi.Risksgraphimage( "", self.objective._id , self.token ).then( function(response){
       
          if( response.data.ok ){

            var md5 = require('md5');

            let base64image = response.data.graphimage
            let nowstring = new Date().toString()

            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

            let imagekey = "image" + nowstring

            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
            var data = {
            Key: md5( imagekey  ) + ".jpg" , 
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
            };

            s3Bucket.putObject(data, function(err, data){
              if (err) { 
                
                console.log(err);
                console.log('Error uploading data: ', data); 
              
              } else {

                //console.log( "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" , 'successfully uploaded the image!');
                //let graphimageurl = "<img src=\"https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" + "\" style=\"width: 300px;\" class=\"fr-fic fr-dib\">"

                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + md5( imagekey  ) + ".jpg"

                self.uploadedgraphimage = graphimageurl

                Userapi.Riskscomputed( "", self.objective._id , self.token ).then( function(response){

                  //if( response.data.ok ){
                    self.riskaggregation = response.data.aggregation
                    self.risks = response.data.risks//self.sortbycxp(response.data.risks) 
                    self.preselecter = self.getdefaultselectvalue()
                    self.rendermessage()
                  //}

                }) 


              }
            })

          }else{
            self.preselecter = self.getdefaultselectvalue()
          }
            


          })


          
        }
      }

        


        if( callback ){
          callback()
        }

      //}

      })
      .catch( function(){
        //self.cockpitsettingsmodalshown = false
      })
      .finally( () => {
      })



      },




      rendermessage(){


        function sortmilestones( a, b ) {
          if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
            return -1;
          }
          if ( new Date(a.eventdate) > new Date(b.eventdate) ){
            return 1;
          }
          return 0;
        }


        let self = this

        Userapi.Tracks( this.cockpit._id, this.token, "open" ).then( function(response){

          if( response.data.ok ){

            let newtracks = []

            for( let i in response.data.tracks ){

              let trk = response.data.tracks[i]

              if( trk.milestones ){
                trk.milestones.sort( sortmilestones );
              }

              newtracks.push( trk )
            }


            self.tracks = newtracks
          }


          var el = document.getElementById( 'risktable2' );
          setTimeout( function(){


            
            self.message = el.innerHTML
            $('#risktable2').hide()

          }, 100)

        })  

        
        
      },

      modalhidden(){

        this.froalaconfig.events.contentChanged = function(){}
        //this.linkInitControls.destroy()
       //## this.validforposting = false
        this.preselecter = { html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false},
        this.$emit('onmodalhidden')
        this.$func.removeQuery(['page'],this.$route, this.$router)
        this.isshown = false

        this.message = ""
      },

      addnewindicator(){

        this.withChanges = false
        this.forceclose = true

        var editorvalue = this.linkInitControls.getEditor().html.get()

        const parser = new DOMParser();
        let htmltext = parser.parseFromString(editorvalue, 'text/html');


        let removeids = ["adddescription", "statusheaderh3", 'statustable']

        for( var i in removeids ){

          let toremove = htmltext.getElementById( removeids[i] )
          
          if( toremove  ){
              toremove.remove()
            }

        }

        let objectivedescriptionhtml = parser.parseFromString(this.objective.description, 'text/html');
        let boarddescriptionhtml = parser.parseFromString(this.objective.description, 'text/html');

        let newhtml = htmltext.body.innerText
        let newobjectivedescription = objectivedescriptionhtml.body.innerText
        let newboarddescription = boarddescriptionhtml.body.innerText
        
        let metadata = { "board":{ name:this.cockpit.name, description:newboarddescription },"objective":{ name:this.objective.name, description:newobjectivedescription },"usertext":newhtml, "tracks":this.tracks,"stakeholders":this.stakeholders,"risks":this.risks }
        

        this.addnewerror = ""

        this.status = 'loading'
        

        let self = this 
        Cockpitsapi.Objectiveindicatorsadd( { "metadata":JSON.stringify(metadata), "statusoptionid":this.preselecter.refid, "cockpitid":this.$route.params.cid, "userid": this.userid, "measureid": this.objective._id, "calculatedindex":this.calculatedindex, "calculatedindexcolor":this.calculatedindexcolor, "statusmessage":editorvalue, "statusmessagetext": editorvalue } ).then( function(response){

          if( response.data.ok ){
            self.$emit('onaddnewindicatorsuccess', self.objective._id, response.data.ind)
            self.addnewerror = ""
          }else{
            self.addnewerror = response.data.error
          }

                })
        .catch(error => console.log(error))
        .finally( () => {
            this.status = 'idle'
                  
        })



      },
    }

  }

</script>